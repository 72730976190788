import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  // FIXME: Use this to retrieve the blob information this.usersApi.v0UsersUserIdAvatarGet({ userId: this.user.id })
  private readonly http = inject(HttpClient);

  private readonly avatarCache = new Map<string, string>();

  async get(userId: string) {
    if (this.avatarCache.has(userId)) {
      return this.avatarCache.get(userId);
    }

    const url = await this.getAvatarUrl(userId);
    this.avatarCache.set(userId, url);
    return url;
  }

  invalidate(userId: string) {
    this.avatarCache.delete(userId);
  }

  private async getAvatarUrl(userId: string) {
    const res = await firstValueFrom(this.http.get(
      `${environment.apiBasePath}/v0/users/${userId}/avatar`,
      {
        responseType: 'blob',
      },
    ));

    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        resolve(reader.result as string);
      }, false);

      if (res) {
        reader.readAsDataURL(res);
      }
    });
  }
}
