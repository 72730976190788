import { Injectable, inject } from '@angular/core';
import {
  SheetsService as SheetApi,
} from 'ngx-atred-api-connectors';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CheckpointsService {
  private readonly sheetsApi = inject(SheetApi);

  async retrieveLastCheckpoint(sheetId: string) {
    try {
      return await firstValueFrom(this.sheetsApi.v0SheetsSheetIdCheckpointsGet({ sheetId }).pipe(
        map((checkpoints) => checkpoints
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).at(0)),
      ));
    } catch {
      throw new Error('Unable to retrieve checkpoints');
    }
  }

  async checkExistingCheckpoints(sheetId: string) {
    const res = await this.retrieveLastCheckpoint(sheetId);
    return res !== undefined;
  }
}
