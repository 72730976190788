export interface UITypology {
  id: string;
  label: string;
}

// FIXME: Apply translations
export const typologies: UITypology[] = [
  {
    id: '7596c971-3918-484f-8597-1843d1e0e1a2',
    label: 'Riunioni',
  },
  {
    id: 'a158c754-284f-445e-beaf-a347127c756a',
    label: 'Operatività',
  },
  {
    id: '2c1a642d-5bb8-4d4c-a3d5-1f468345d7de',
    label: 'Urgenze',
  },
  {
    id: '96a82632-4197-4024-b572-c5a1f3cb4f8a',
    label: 'Progetto',
  },
  {
    id: 'b95f5b12-0ed7-422d-8ff7-efd3b6175522',
    label: 'Sviluppo persone',
  },
  {
    id: '4a59674e-06f3-4983-ae12-4cbcc9d9c312',
    label: 'Formazione',
  },
  {
    id: 'e939f3e2-dcb7-4db1-b717-796552bae7ce',
    label: 'Miglioramento',
  },
  {
    id: '704e5aeb-2552-4e7b-9d4d-634f27042834',
    label: 'Elaborazione dati',
  },
  {
    id: '3e7f0474-b81a-450b-85f5-79f2e423e38e',
    label: 'Telefonate',
  },
  {
    id: 'f3ae138f-df6e-419b-a563-64112a9144bf',
    label: 'Burocrazia',
  },
  {
    id: '13f24c3d-5585-4ee2-b272-bc1a556df3f0',
    label: 'Mail',
  },
  {
    id: '70ac4ee4-a9d2-4e44-9f91-457d642850ba',
    label: 'Altro',
  },
];

export const getTypeById = (id: string) => typologies.find((t) => t.id === id);
