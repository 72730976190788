import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  Provider,
  forwardRef,
  inject,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { ApiModule } from 'ngx-atred-api-connectors';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CachedHttpLoader } from './cached-http-loader';
import { ApiInterceptor } from './core/interceptors/api-interceptor.service';
import { AuthService } from './core/services';
import { DashboardModule } from './dashboard/dashboard.module';
import { GenericDialogComponent } from './shared/generic-dialog';
import { environment } from '../environments/environment';

const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true,
};

const HttpLoaderFactory = (http: HttpBackend) => new CachedHttpLoader(http, './assets/i18n/', '.json');

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ApiModule.forRoot({
      rootUrl: environment.apiBasePath,
    }),
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLang,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    AuthModule,
    DashboardModule,
    GenericDialogComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: environment.defaultLang },
    API_INTERCEPTOR_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  private readonly authService = inject(AuthService);

  constructor() {
    void this.authService.init();
  }
}
