/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import { Activity, Visibility } from 'ngx-atred-api-connectors';

import { compareTimes, subtractTimes, sumTimes } from './time';
import { ActivityFilter } from '../../shared/components/activity-filters/activity-filters.component';

export const DEFAULT_TYPOLOGY = { id: '00000000-0000-0000-0000-000000000000', label: '' };

export const goldActivitiesCount = (
  activities: Activity[],
) => activities.filter(({ isGold }) => isGold).length;

export const retrieveInitialTimesSum = (
  activities: Activity[],
) => sumTimes(activities
  .map(({ initialTimeSpan }) => initialTimeSpan ?? '00:00:00'));

export const retrieveTargetTimesSum = (
  activities: Activity[],
) => sumTimes(activities
  .map(({ initialTimeSpan, targetTimeSpan }) => targetTimeSpan ?? initialTimeSpan ?? '00:00:00'));

export const retrieveMeasuredTimesSum = (activities: Activity[]) => sumTimes(
  activities
    .map((activity) => activity.measurements.at(-1)?.timeSpan ?? activity.initialTimeSpan ?? '00:00:00'),
);

export const retrieveGoldTimeSum = (
  activities: Activity[],
) => retrieveMeasuredTimesSum(
  activities.filter(({ isGold }) => isGold),
);

export const retrieveGoldInitialTimeSum = (
  activities: Activity[],
) => retrieveInitialTimesSum(
  activities.filter(({ isGold }) => isGold),
);

export const retrieveGoldTargetTimeSum = (
  activities: Activity[],
) => retrieveTargetTimesSum(
  activities.filter(({ isGold }) => isGold),
);

export const retrieveReductionTimesOnTargetSum = (activities: Activity[]) => {
  const relevantActivities = activities
    .filter(({ targetTimeSpan }) => targetTimeSpan)
    // Filter activities with target time greater than initial time
    .filter((activity) => compareTimes(
      activity.initialTimeSpan ?? '00:00:00',
      activity.targetTimeSpan ?? '00:00:00',
    ));

  return sumTimes(
    relevantActivities
      .map((activity) => subtractTimes(
        activity.initialTimeSpan ?? '00:00:00',
        activity.targetTimeSpan ?? '00:00:00',
      )),
  );
};

export const retrieveIncreaseTimesOnTargetSum = (activities: Activity[]) => {
  const relevantActivities = activities
    .filter(({ targetTimeSpan }) => targetTimeSpan)
    // Filter activities with initial times greater than target time
    .filter((activity) => compareTimes(
      activity.targetTimeSpan ?? '00:00:00',
      activity.initialTimeSpan ?? '00:00:00',
    ));

  // Sum the differences between each activity initial time and target time
  return sumTimes(
    relevantActivities
      .map((activity) => subtractTimes(
        activity.targetTimeSpan ?? '00:00:00',
        activity.initialTimeSpan ?? '00:00:00',
      )),
  );
};

export const retrieveReductionTimesOnMeasuredSum = (activities: Activity[]) => {
  const relevantActivities = activities
    .filter(({ measurements }) => !!measurements.length)
    // Filter activities with measured times greater than initial time
    .filter((activity) => compareTimes(
      activity.initialTimeSpan ?? '00:00:00',
      activity.measurements.at(-1)?.timeSpan ?? '00:00:00',
    ));

  // Sum the differences between each activity measured time and initial time
  return sumTimes(
    relevantActivities
      .map((activity) => subtractTimes(
        activity.initialTimeSpan ?? '00:00:00',
        activity.measurements.at(-1)?.timeSpan ?? '00:00:00',
      )),
  );
};

export const retrieveIncreaseTimesOnMeasuredSum = (activities: Activity[]) => {
  const relevantActivities = activities
    .filter(({ measurements }) => !!measurements.length)
    // Filter activities with initial times greater than measured time
    .filter((activity) => compareTimes(
      activity.measurements.at(-1)?.timeSpan ?? '00:00:00',
      activity.initialTimeSpan ?? '00:00:00',
    ));

  // Sum the differences between each activity measured time and initial time
  return sumTimes(
    relevantActivities
      .map((activity) => subtractTimes(
        activity.measurements.at(-1)?.timeSpan ?? '00:00:00',
        activity.initialTimeSpan ?? '00:00:00',
      )),
  );
};

export const retrieveGoldActivityPercentage = (activities: Activity[]) => {
  const goldActivitiesCount = activities
    .filter(({ isGold }) => isGold).length;

  return (goldActivitiesCount / Math.max(activities.length, 1)) * 100;
};

export const retrieveActivityCount = (activities: Activity[]) => activities.length;

export const retrieveWorkingActivities = (
  activities: Activity[],
) => activities.filter(({ visibility }) => visibility === Visibility.Public);

export const retrieveGoldActivityCount = (
  activities: Activity[],
) => activities.filter(({ isGold }) => isGold).length;

export const retrieveLeversCount = (
  activities?: Activity[],
  lever?: string,
) => {
  if (!activities) {
    return 0;
  }

  let total = 0;
  for (const activity of activities) {
    if (!lever) {
      total += activity.improvementActions.length;
    } else {
      total += activity.improvementActions.filter((action) => action.lever.includes(lever === 'delete'
        ? 'eliminate'
        : lever)).length;
    }
  }

  return total;
};

export const filterSheetActivity = (query?: string, list?: Activity[], filter?: ActivityFilter) => {
  const queriedActivities = list?.filter((item) => new RegExp(query ?? '.', 'i').test(item.name));
  if (!filter) {
    return queriedActivities;
  }

  const filtersToApply = Object.keys(filter)
    .filter((key) => (filter[key as keyof ActivityFilter] !== undefined));

  const filteredQueriedActivities = queriedActivities?.filter((activity) => {
    let included = true;
    // eslint-disable-next-line guard-for-in
    for (const filterField of filtersToApply) {
      const activityField = activity[filterField as keyof Activity];
      if (Array.isArray(activityField)) {
        included = activityField
          .some((el) => typeof el === 'string' && (filter[filterField as keyof ActivityFilter] as Array<string>)
            .includes(el));
      } else {
        included = activity[filterField as keyof Activity] === filter[filterField as keyof ActivityFilter];
      }

      if (!included) {
        break;
      }
    }
    return included;
  });

  return filteredQueriedActivities;
};
