import {
  NgClass,
  NgFor,
  NgIf,
  NgStyle,
} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroXMark, heroPencil } from '@ng-icons/heroicons/outline';
import { TranslateModule } from '@ngx-translate/core';
import { Tag } from 'ngx-atred-api-connectors';

const colors = [
  '#6366F1',
  '#A3E635',
  '#22D3EE',
  '#34D399',
  '#EC4899',
  '#FDBA74',
  '#35D399',
  '#EF4899',
  '#F97171',
  '#F87171',
  '#6566F1',
  '#A4E635',
];

@Component({
  selector: 'app-tag-item',
  templateUrl: './tag-item.component.html',
  styleUrls: ['./tag-item.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatTooltipModule,
    NgClass,
    NgFor,
    NgIconComponent,
    NgIf,
    NgStyle,
    ReactiveFormsModule,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroXMark,
      heroPencil,
    }),
  ],
})
export class TagItemComponent implements OnInit {
  @Input() tag?: Tag;
  @Input() isEditing = false;
  @Input() selected = false;

  @Output() readonly save = new EventEmitter<Tag | Exclude<Tag, 'id'>>();
  @Output() readonly toggleApply = new EventEmitter<Tag>();

  private readonly fb = inject(FormBuilder);
  readonly newTagForm = this.fb.nonNullable.group({
    name: ['', Validators.required],
    color: ['#6366F1', Validators.required],
  });

  isSelectingColors = false;

  readonly colors = colors;

  ngOnInit() {
    if (!this.tag) {
      return;
    }

    this.newTagForm.setValue({
      name: this.tag.name,
      color: this.tag.color || '#6366F1',
    });
  }

  undoEditTag() {
    this.isEditing = false;
    this.isSelectingColors = false;

    this.ngOnInit();
  }

  onSubmit() {
    if (this.newTagForm.invalid) {
      return;
    }

    if (this.tag) {
      this.save.emit({
        id: this.tag.id,
        ...this.newTagForm.getRawValue(),
      });
    } else {
      this.save.emit({ ...this.newTagForm.getRawValue() as Tag });
    }

    this.isEditing = false;
  }
}
