<div class="flex h-full w-full flex-row items-start justify-center p-4">
  <div class="flex h-full w-full flex-col items-center justify-center">
    <router-outlet></router-outlet>
    <div class="mx-4 flex flex-row items-center justify-center py-2">
      <div class="flex w-full flex-col items-center justify-center text-center text-sm">
        <span><!-- [TODO] NEYM_ATRED_LOGO --></span>
        <span>{{ 'i18n.Common.rights' | translate }}</span>
      </div>
    </div>
  </div>

  <div class="hidden h-full w-full flex-col items-center justify-center rounded-2xl bg-brand-light md:flex">
    <div class="mx-auto flex flex-col items-center justify-center gap-4 px-10">
      <img
        class="min-h-0 max-w-md"
        src="../../../assets/placeholders/join.svg">
      <div [innerHTML]="'i18n.Common.appDescription' | translate"></div>
    </div>
  </div>
</div>
