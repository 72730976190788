import { Injectable, inject } from '@angular/core';
import { Document, Sheet } from 'ngx-atred-api-connectors';
import { BehaviorSubject, filter, map } from 'rxjs';

import { AuthService, getUserId } from '../../core/services';

interface SimpleSheet {
  id: string;
  userId: string;
  document: Document;
}

const isSheetOwner = (userSheetId: string, currentUserId: string) => userSheetId === currentUserId;

@Injectable()
export class ActiveSheetService {
  private readonly authService = inject(AuthService);

  readonly sheet$ = new BehaviorSubject<Sheet | undefined>(undefined);
  private readonly simpleSheet$ = new BehaviorSubject<SimpleSheet | undefined>(undefined);

  readonly isOwner$ = this.simpleSheet$.pipe(
    filter((s): s is SimpleSheet => !!s),
    map((s) => {
      const userId = getUserId(this.authService.accessToken);
      if (userId) {
        return isSheetOwner(s?.userId, userId);
      }
      return false;
    }),
  );

  readonly sheetId$ = this.simpleSheet$.pipe(
    filter((s): s is SimpleSheet => !!s),
    map(({ id }) => id),
  );

  readonly document$ = this.simpleSheet$.pipe(
    filter((s): s is SimpleSheet => !!s),
    map(({ document }) => document),
  );

  get sheet() {
    return this.sheet$.value;
  }

  setActiveSheet(sheet: Sheet) {
    this.sheet$.next(sheet);

    this.setActiveSheetFromCheckpoint({
      id: sheet.id,
      userId: sheet.userId,
      document: sheet.document,
    });

    return sheet;
  }

  // FIXME: Have an unique way to set active information
  setActiveSheetFromCheckpoint(sheet: SimpleSheet) {
    this.simpleSheet$.next(sheet);
    return sheet;
  }
}
