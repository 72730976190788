/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable class-methods-use-this */
import {
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
  inject,
} from '@angular/core';
import {
  ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroCheck,
} from '@ng-icons/heroicons/outline';
import { TranslateModule } from '@ngx-translate/core';
import { Tag } from 'ngx-atred-api-connectors';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { SheetsService } from '../../../core/services/sheets.service';
import { required } from '../../../core/utils';
import { ActiveSheetService } from '../../../document/services/active-sheet.service';
import { TagItemComponent } from '../tag-menu/tag-item/tag-item.component';
import { TagMenuComponent } from '../tag-menu/tag-menu.component';
import { TagMenuBottomSheetComponent } from '../tag-menu-bottom-sheet/tag-menu-bottom-sheet.component';

@Component({
  selector: 'app-activity-tags',
  templateUrl: './activity-tags.component.html',
  styleUrls: ['./activity-tags.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatMenuModule,
    MatTooltipModule,
    NgClass,
    NgFor,
    NgIconComponent,
    NgIf,
    TagItemComponent,
    TagMenuComponent,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroCheck,
    }),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActivityTagsComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ActivityTagsComponent implements ControlValueAccessor {
  private readonly activeSheetService = inject(ActiveSheetService);
  private readonly sheetsService = inject(SheetsService);

  @Input() readOnly = true;
  @Input() editing = false;
  private readonly bottomSheet = inject(MatBottomSheet);

  private readonly tags$ = new BehaviorSubject<Tag[] | undefined>(undefined);
  @Input() set tags(val: Tag[] | undefined) {
    this.tags$.next(val?.sort((a, b) => a.name.localeCompare(b.name)));
    this.onChange(val);
    this.onTouch(val);
  }
  get tags() {
    return this.tags$.value;
  }

  readonly query$ = new BehaviorSubject('');
  get query() {
    return this.query$.value;
  }
  set query(value: string) {
    this.query$.next(value);
  }

  isCreatingTag = false;
  isLoading = false;
  expanded = false;

  onChange: any = () => { };
  onTouch: any = () => { };

  writeValue(value: Tag) {
    if (!value.id || !this.tags) {
      return;
    }

    this.tags.find((t) => t.id === value.id)
      ? this.tags = this.tags.filter((tag) => tag.id !== value.id)
      : this.tags = [...this.tags, value];
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  async editTag(tag: Tag) {
    if (this.tags && this.tags.some((t) => t.id === tag.id)) {
      const updatedTags = [...this.tags.filter((myTag) => myTag.id !== tag.id), tag];
      this.tags = updatedTags;
    }

    try {
      this.isLoading = true;
      let { sheet } = this.activeSheetService;
      required(sheet);

      sheet = await this.sheetsService.updateTag(sheet, tag);

      this.activeSheetService.setActiveSheet(sheet);
      this.isCreatingTag = false;
    } catch (e) {
      throw new Error(`Got: ${e}`);
    } finally {
      this.isLoading = false;
    }
  }

  async openTagBottomSheet() {
    const res = await firstValueFrom(this.bottomSheet.open(TagMenuBottomSheetComponent, {
      data: this.tags,
    }).afterDismissed());

    if (res && res.edit) {
      await this.editTag(res.edit);
      return;
    }

    if (res) {
      this.writeValue(res);
    }
  }
}
