import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroBellAlert,
  heroCog,
  heroDocument,
  heroLightBulb,
  heroShare,
  heroUserGroup,
} from '@ng-icons/heroicons/outline';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-sidenav-link',
  templateUrl: './sidenav-link.component.html',
  styleUrls: ['./sidenav-link.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIconComponent,
    NgIf,
    RouterLink,
    RouterLinkActive,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroBellAlert,
      heroCog,
      heroDocument,
      heroLightBulb,
      heroShare,
      heroUserGroup,
    })],
})

export class SidenavLinkComponent {
  @Input() icon = '';
  @Input() label = '';
  @Input() link?: string;
  @Input() count?: number | null;
}
