<div
  class="flex h-full min-h-[80vh] flex-col gap-2"
  [ngSwitch]="getCurrentStep()">
  <div class="sticky top-2 z-10 p-4">
    <div class="flex max-h-10 w-full grow flex-row items-center gap-1 rounded-lg border border-gray-200 bg-white px-3 py-2 font-light tracking-wide text-gray-400 shadow-sm">
      <ng-icon
        class="mr-1 text-2xl"
        name="heroMagnifyingGlass">
      </ng-icon>
      <input
        class="w-full bg-transparent text-lg text-gray-900"
        [placeholder]="'i18n.Common.search' | translate"
        [(ngModel)]="query">
      <ng-icon
        class="cursor-pointer text-2xl"
        name="heroXMark"
        [class.invisible]="!query"
        (click)="query = ''">
      </ng-icon>
    </div>
  </div>

  <ng-container *ngSwitchCase="'select-sheet'">
    <ng-container *ngIf="{ sheets: sheets$ | async } as resolved">
      <div
        *ngIf="!query && resolved.sheets?.length === 0; else showList"
        class="flex h-full w-full flex-col items-center justify-center gap-6 font-bold text-gray-600">
        {{ 'i18n.MyDocumentsPage.empty' | translate }}
      </div>

      <ng-template #showList>
        <app-document-list
          class="overflow-y-scroll px-4"
          [sheets]="resolved.sheets"
          [(selected)]="selectedSheet">
        </app-document-list>
      </ng-template>

      <ng-template #loadingListHeader>
        <div class="ml-1 mt-4">
          <span class="block h-3 w-24 animate-pulse rounded bg-black/10"></span>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'select-activity'">
    <div
      *ngIf="{ activities: activitiesList$ | async } as resolved"
      class="overflow-hidden !overflow-y-scroll">
      <div
        *ngFor="let activity of resolved.activities"
        class="px-4 py-2">
        <app-activity
          [activity]="activity"
          [class.selected]="activity.id === selectedActivity?.id"
          (click)="selectedActivity$.next(activity)"></app-activity>
      </div>
    </div>
  </ng-container>

  <div class="sticky bottom-0 mt-auto flex w-full flex-row items-center gap-2 bg-white px-2 py-4">
    <button
      class="w-full rounded-full border border-gray-400 bg-white px-6 py-2 text-sm text-gray-400 hover:opacity-50 md:w-fit"
      type="button"
      (click)="goToPreviousStep()">
      {{ 'i18n.Common.previous' | translate }}
    </button>
    <button
      class="w-full rounded-full bg-core-accent px-6 py-2 text-sm text-white md:w-fit"
      type="submit"
      [ngClass]="{
        'opacity-40 cursor-not-allowed' : !canProceed()
      }"
      [disabled]="!canProceed()"
      (click)="goToNextStep()">
      <span>{{ 'i18n.Common.next' | translate }}</span>
    </button>
  </div>
</div>
