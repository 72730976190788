import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AvatarService } from '../../../core/services';

export enum AvatarSize {
  ExtraSmall = 'ExtraSmall',
  Small = 'Small',
  Large = 'Large'
}

@Component({
  selector: 'app-user-identity',
  templateUrl: './user-identity.component.html',
  styleUrls: ['./user-identity.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    TranslateModule,
  ],
})
export class UserIdentityComponent implements OnChanges {
  private readonly avatarService = inject(AvatarService);

  @Input() user?: {
    id: string;
    firstName?: string;
    lastName?: string;
    avatarUrl?: string | null;
    hasAvatar?: boolean | null;
  } | null;
  @Input() size?: AvatarSize = AvatarSize.Large;

  @ViewChild('avatar') avatarElement!: ElementRef<HTMLImageElement>;

  avatarUrl?: string;

  readonly AvatarSize = AvatarSize;

  async ngOnChanges() {
    if (!this.user) {
      return;
    }
    if (!this.user.hasAvatar) {
      return;
    }

    const url = await this.avatarService.get(this.user.id);

    if (url) {
      this.avatarElement.nativeElement.src = url;
      this.avatarUrl = url;
    }
  }
}
