<div
  *ngIf="sheets; else loading"
  class="flex flex-col gap-2">
  <ng-container *ngIf="!selectedChange.observers.length; else selectables">
    <app-document-list-item
      *ngFor="let sheet of sheets; let last = last; let index = index; trackBy: trackById"
      class="cursor-pointer"
      [class.mb-6]="last"
      [class.opacity-50]="sheet?.state === DocumentState.Closed"
      [sheet]="sheet"
      [routerLink]="['/document', sheet.id, 'define']"
      (toggleState)="toggleState.emit($event)"
      (remove)="remove.emit($event)"
      (share)="share.emit($event)"
      (edit)="edit.emit($event)">
    </app-document-list-item>
  </ng-container>

  <ng-template #selectables>
    <app-document-list-item
      *ngFor="let sheet of sheets; let last = last; let index = index; trackBy: trackById"
      class="cursor-pointer"
      [ngClass]="{
        'mb-6': last,
        'opacity-50': sheet?.state === DocumentState.Closed,
        'selected': selected?.id === sheet.id
      }"
      [class.opacity-50]="sheet?.state === DocumentState.Closed"
      [sheet]="sheet"
      (click)="selectedChange.emit(sheet)">
    </app-document-list-item>
  </ng-template>
</div>

<ng-template #loading>
  <div class="flex flex-col gap-2">
    <app-document-list-item></app-document-list-item>
    <app-document-list-item></app-document-list-item>
  </div>
</ng-template>
