<div class="flex h-full w-full flex-col items-start justify-between bg-white py-2 font-light tracking-wide max-sm:rounded-lg">
  <span class="sticky top-0 z-30 w-full shrink-0 grow border-b border-gray-200 bg-white px-4 py-2 text-xl text-black max-sm:line-clamp-2">
    {{ 'i18n.DefinePage.selectTypology' | translate }}
  </span>

  <div class="flex w-full flex-col justify-start">
    <button
      *ngFor="let t of typologies"
      mat-menu-item
      (click)="bottomSheetRef.dismiss(t)">
      <div class="flex flex-row items-center justify-between border-b border-gray-200 px-4 py-2">
        <span>{{ t.label| translate }}</span>
        <ng-icon
          *ngIf="t.id === selected.id"
          name="heroLockClosed">
        </ng-icon>
      </div>
    </button>
  </div>
</div>
