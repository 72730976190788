<ng-container *ngIf="{ documentTagsList: tagsList$ | async } as resolved">
  <div
    class="flex h-full min-h-[400px] flex-col pb-4"
    (click)="$event.stopPropagation()">
    <div class="sticky top-0 z-40 md:mb-2">
      <div class="flex max-h-10 w-full flex-row items-center gap-1 border-b border-gray-200 bg-white px-3 py-2 font-light tracking-wide text-gray-400 shadow-sm">
        <ng-icon
          class="mr-1 text-xl max-sm:text-2xl"
          name="heroMagnifyingGlass">
        </ng-icon>
        <input
          class="w-full bg-transparent text-base text-gray-900"
          [placeholder]="'i18n.Common.search' | translate"
          [(ngModel)]="query">
        <ng-icon
          class="cursor-pointer text-base max-sm:text-2xl"
          name="heroXMark"
          [class.invisible]="!query"
          (click)="query = ''">
        </ng-icon>

        <ng-icon
          *ngIf="!isCreatingTag"
          class="cursor-pointer text-xl max-sm:text-2xl"
          name="heroPlus"
          (click)="isCreatingTag = true">
        </ng-icon>
      </div>
    </div>

    <ng-container *ngIf="resolved.documentTagsList && resolved.documentTagsList?.length === 0 && !query && !isCreatingTag; else tagList">
      <div class="flex h-full flex-col items-center justify-center gap-2 text-gray-600">
        <span>{{ 'i18n.TagMenu.empty' | translate }}</span>
        <button
          class="flex flex-row items-center gap-2 rounded-full bg-core-accent px-6 py-2 text-lg font-normal text-white"
          (click)="isCreatingTag = true">
          {{ 'i18n.TagMenu.createNewTag' | translate }}
          <ng-icon
            class="text-xl"
            name="heroPlus">
          </ng-icon>
        </button>
      </div>
    </ng-container>

    <ng-template #tagList>
      <app-tag-item
        *ngIf="isCreatingTag"
        [isEditing]="true"
        (save)="addNewTag($event)">
      </app-tag-item>

      <div *ngIf="!resolved.documentTagsList">
        <div class="flex flex-col items-start gap-3 px-4 py-2">
          <div
            *ngFor="let item of [].constructor(3)"
            class="flex w-full flex-row items-center gap-4 border-b border-gray-200 py-2">
            <div class="h-6 w-6 shrink-0 animate-pulse rounded bg-black/10"></div>
            <div class="mr-4 h-6 w-52 animate-pulse rounded bg-black/10"></div>
            <div class="ml-auto h-6 w-6 shrink-0 animate-pulse rounded bg-black/10"></div>
          </div>
        </div>
      </div>

      <ng-container *ngFor="let possibileTag of resolved.documentTagsList; let index = index">
        <app-tag-item
          [tag]="possibileTag"
          [selected]="isSelected(myTags, possibileTag.id)"
          (save)="edit.emit($event)"
          (toggleApply)="selection.emit(possibileTag)">
        </app-tag-item>
      </ng-container>
    </ng-template>
  </div>
</ng-container>
