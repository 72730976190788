<div class="flex flex-row items-center gap-1 max-sm:flex-wrap">
  <div
    *ngFor="let tag of tags; let first = first"
    class="line-clamp-1 max-w-xs shrink-0 cursor-pointer rounded px-1 py-[2px] text-xs text-white max-sm:pointer-events-none"
    [ngClass]="{
      'pointer-events-none touch-none': readOnly
    }"
    [style.backgroundColor]="tag.color"
    [matMenuTriggerFor]="tagMenu">
    {{ tag.name }}
  </div>

  <div
    class="sticky right-0 rounded-l bg-white pl-0.5 pr-1 max-sm:hidden"
    [matMenuTriggerFor]="tagMenu">
    <div class="flex w-fit flex-row items-center rounded bg-gray-200 p-1 text-xs uppercase text-gray-900">
      <ng-icon
        class="cursor-pointer text-sm"
        name="heroPlus">
      </ng-icon>
    </div>
  </div>

  <div
    class="md:hidden"
    (click)="!editing ? openTagBottomSheet() : expanded = true">
    <div class="flex w-fit flex-row items-center rounded bg-gray-200 p-1 text-xs uppercase text-gray-900">
      <ng-icon
        class="cursor-pointer text-sm"
        name="heroPlus">
      </ng-icon>
    </div>
  </div>
</div>

<div
  *ngIf="expanded"
  class="absolute inset-0 z-50 flex w-full flex-col justify-start bg-white">
  <div class="sticky top-0 flex w-full items-center gap-1 overflow-hidden border-b border-gray-200 bg-white px-2 py-4 text-xl text-black">
    <ng-icon
      class="mr-1 cursor-pointer text-xl"
      name="heroArrowSmallLeft"
      (click)="$event.stopPropagation(); expanded = false">
    </ng-icon>
    <span>
      {{ 'i18n.DefinePage.selectTags' | translate }}
    </span>
  </div>
  <div class="flex w-full flex-col justify-start overflow-y-scroll">
    <app-tag-menu
      class="w-full"
      [class.opacity-50]="isLoading"
      [myTags]="tags"
      (edit)="editTag($event)"
      (selection)="writeValue($event)"></app-tag-menu>
  </div>
</div>

<mat-menu
  #tagMenu="matMenu"
  class="context-menu">
  <app-tag-menu
    [class.opacity-50]="isLoading"
    [myTags]="tags"
    (edit)="editTag($event)"
    (selection)="writeValue($event)"></app-tag-menu>
</mat-menu>
