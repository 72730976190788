import {
  Group,
  GroupParticipantRole,
  ListGroupParticipantsResponse,
} from 'ngx-atred-api-connectors';

export type GroupWithParticipants = Group & ListGroupParticipantsResponse;

export function isPendingGroup(g?: GroupWithParticipants, userId?: string) {
  // No document shared ...
  return !g?.sheet
    // ...even if my role is "member"
    && g?.participants.some((p) => p.user.id === userId && p.role === GroupParticipantRole.Member);
}
