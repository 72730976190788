import { Injectable, inject } from '@angular/core';
import {
  Activity,
  Measurement,
  RealTimeMeasurement,
  RealTimeMeasurementsService,
  RealTimeMeasurementState,
} from 'ngx-atred-api-connectors';
import {
  BehaviorSubject,
  Observable,
  map,
  combineLatest,
  switchMap,
  interval,
  EMPTY,
  tap,
  Subscription,
  firstValueFrom,
  startWith,
} from 'rxjs';

import {
  addTimes,
  required,
  Stopwatch,
  transformHoursMinutesSecondsToTimespan,
  transformTimeSpanToSec,
} from '../utils';

type UIMeasureState = 'stop' | 'play' | 'pause' | 'resume';

export interface Measure {
  id: string; // activity.id
  realTimeId?: string;
  sheetId: string;
  stopwatch: Stopwatch;
  state$: Observable<UIMeasureState>;
  time$: BehaviorSubject<number>;
  enforceTime$: BehaviorSubject<number>;
  timeSubscription: Subscription;
}

const extractUiStateFromRealtime = (realTimeState?: RealTimeMeasurementState): UIMeasureState => {
  if (realTimeState === RealTimeMeasurementState.Running) {
    return 'play';
  }
  if (realTimeState === RealTimeMeasurementState.Paused) {
    return 'resume';
  }
  return 'stop';
};

const initMeasure = (id: string, sheetId: string, init?: RealTimeMeasurement): Measure => {
  let sec = 0;
  if (init) {
    sec = transformTimeSpanToSec(init?.elapsedTime);
  }

  const stopwatch = new Stopwatch();
  if (init?.state === RealTimeMeasurementState.Running) {
    stopwatch.start();
  }

  const enforceTime$ = new BehaviorSubject(sec * 1000);
  const time$ = new BehaviorSubject<number>(sec * 1000);
  const startingState = extractUiStateFromRealtime(init?.state);

  const state$ = combineLatest([
    time$,
    stopwatch.running$,
  ]).pipe(
    map(([time, running]) => {
      if (running) {
        return 'play';
      }

      // not running:
      if (time) {
        return 'resume';
      }
      return 'pause';
    }),
    startWith(startingState),
  );

  const timeCalculation$ = combineLatest([
    enforceTime$.asObservable().pipe(map((time) => ({ time, timestamp: performance.now() }))),
    stopwatch.running$.pipe(
      switchMap((running) => (running
        ? interval(1000).pipe(
          map(() => performance.now()),
        )
        : EMPTY)),
    ),
  ]).pipe(
    map(([enforcedTime, timerTimestamp]) => {
      if (enforcedTime.timestamp > timerTimestamp) {
        return enforcedTime.time;
      }
      return time$.value + 1000;
    }),
    tap((time) => time$.next(time)),
  );
  const timeSubscription = timeCalculation$.subscribe();

  const measure = {
    id,
    sheetId,
    realTimeId: init?.id,
    stopwatch,
    enforceTime$,
    state$,
    time$,
    timeSubscription,
  };
  return measure;
};

@Injectable({
  providedIn: 'root',
})
export class MeasureService {
  private readonly realtimeMeasurementsApi = inject(RealTimeMeasurementsService);

  private readonly _measures: { [id: string]: Measure } = {};
  private readonly _runningMeasure$ = new BehaviorSubject<Measure | undefined>(undefined);

  readonly runningMeasure$ = this._runningMeasure$.asObservable();

  getMeasures(
    activities: Activity[],
    sheetId: string,
    realtimeMeasurements: RealTimeMeasurement[],
  ): Measure[] {
    return activities.map((activity) => {
      const findRealtimeMeasureRelated = realtimeMeasurements.find((m) => m.activityId === activity.id);
      return this.getMeasure(activity.id, sheetId, findRealtimeMeasureRelated);
    });
  }

  getMeasure(id: string, sheetId: string, init?: RealTimeMeasurement): Measure {
    if (!this._measures[id]) {
      this._measures[id] = initMeasure(id, sheetId, init);
    }

    const measure = this._measures[id];
    if (init?.state === RealTimeMeasurementState.Running) {
      this._runningMeasure$.next(measure);
      measure.stopwatch.start();
    }

    return measure;
  }

  async startTimer(id: string, sheetId: string) {
    await this.stopTimer();

    const measure = this.getMeasure(id, sheetId);
    if (!measure.realTimeId) {
      const body = { sheetId: measure.sheetId, activityId: measure.id };
      const res = await firstValueFrom(this.realtimeMeasurementsApi.v0RealTimeMeasurementsPost({ body }));
      measure.realTimeId = res.id;
    } else {
      await firstValueFrom(this.realtimeMeasurementsApi.v0RealTimeMeasurementsIdResumePut({ id: measure.realTimeId }));
    }
    measure.stopwatch.start();
    this._runningMeasure$.next(measure);
  }

  async stopTimer() {
    const runningMeasure = this._runningMeasure$.value;
    if (runningMeasure) {
      runningMeasure.stopwatch.stop();

      required(runningMeasure.realTimeId);
      const res = await firstValueFrom(
        this.realtimeMeasurementsApi.v0RealTimeMeasurementsIdPausePut({ id: runningMeasure.realTimeId }),
      );
      const sec = transformTimeSpanToSec(res.elapsedTime);
      runningMeasure.enforceTime$.next(sec * 1000);
    }
  }

  async endTimer(id: string, sheetId: string) {
    const measure = this.getMeasure(id, sheetId);
    measure.stopwatch.reset();

    required(measure.realTimeId);
    const res = await firstValueFrom(
      this.realtimeMeasurementsApi.v0RealTimeMeasurementsIdEndPut({ id: measure.realTimeId }),
    );

    measure.realTimeId = undefined;
    measure.enforceTime$.next(0);
    measure.time$.next(0);

    this._runningMeasure$.next(undefined);

    return res.elapsedTime;
  }

  async toggleTimer(id: string, sheetId: string) {
    const measure = this.getMeasure(id, sheetId);
    const running = await firstValueFrom(measure.stopwatch.running$);
    if (running) {
      await this.stopTimer();
    } else {
      await this.startTimer(id, sheetId);
    }
  }

  // TODO: use this to possibly improve performances, but take into account mobile's quick measurement
  cleanAll() {
    Object.values(this._measures).forEach((measure) => measure.timeSubscription.unsubscribe());
    Object.keys(this._measures).forEach((key) => delete this._measures[key]);
  }

  async discardRealtimeMeasure(latestRunningMeasure: Measure) {
    await this.endTimer(latestRunningMeasure.id, latestRunningMeasure.sheetId);
  }

  public async confirmRealtimeMeasure(
    latestRunningMeasure: Measure,
    currentActivity: Activity,
    currentMeasure: Measurement | undefined,
    ownTime: string | null,
    activeWeek: number,
    isLatestRunningMeasureManuallyEdit = false,
  ) {
    const endElapsedTime = await this.endTimer(
      latestRunningMeasure.id,
      latestRunningMeasure.sheetId,
    );

    required(currentActivity);
    required(endElapsedTime);
    required(ownTime);

    const updatedActivity: Activity = JSON.parse(JSON.stringify(currentActivity));
    const timeSpanToAdd = transformHoursMinutesSecondsToTimespan(
      isLatestRunningMeasureManuallyEdit
        ? ownTime
        : endElapsedTime,
    );

    const timeSpan = currentMeasure
      ? transformHoursMinutesSecondsToTimespan(addTimes(currentMeasure.timeSpan, timeSpanToAdd))
      : timeSpanToAdd;

    updatedActivity.measurements = currentMeasure
      ? updatedActivity.measurements.map((m) => (m.week === activeWeek ? { ...m, timeSpan } : m))
      : [...updatedActivity.measurements, { week: activeWeek, timeSpan: endElapsedTime, interruptions: 0 }];

    return updatedActivity;
  }
}
