import {
  filter,
  map,
  Observable,
  ObservableInput,
  of,
  startWith,
  switchMap,
  timer,
} from 'rxjs';

export const nullThenValueDelayedBy = (ms: number) => <T>(x: T) => timer(ms).pipe(map(() => x), startWith(null));

export const switchMapTail = <T, O extends ObservableInput<unknown>>(f: (value: T) => O) => switchMap(
  (x: T, i) => (i === 0 ? of(x) : f(x)),
);

export const inputIsNotNullOrUndefined = <T>(input: T): input is Exclude<T, null | undefined> => input !== null
  && input !== undefined;

export const isNotNullOrUndefined = <T>(): (
  source$: Observable<T>,
) => Observable<Exclude<T, null | undefined>> => filter(inputIsNotNullOrUndefined);
