<ng-container *ngIf="typology">
  <div
    class="shrink-0 cursor-pointer truncate rounded-xl border border-core-light px-1 py-[2px] text-xs font-semibold text-core-light"
    [ngClass]="{
      'pointer-events-none touch-none': readOnly
    }">
    <span
      class="max-sm:hidden"
      [matMenuTriggerFor]="typologyMenu"
      (click)="$event.stopPropagation()">
      {{ typology.label === ''
    ? ('i18n.Common.typology' | translate)
    : typology.label }}
    </span>

    <span
      class="md:hidden"
      (click)="!editing ? openTypologyBottomSheet() : expanded = true">
      {{ typology.label === ''
    ? ('i18n.Common.typology'| translate)
    : typology.label }}
    </span>
  </div>

  <div
    *ngIf="expanded"
    class="absolute inset-0 z-50 flex w-full flex-col justify-start bg-white">
    <div class="sticky top-0 flex w-full items-center gap-1 overflow-hidden border-b border-gray-200 bg-white px-2 py-4 text-xl text-black">
      <ng-icon
        class="mr-1 cursor-pointer text-xl"
        name="heroArrowSmallLeft"
        (click)="$event.stopPropagation(); expanded = false">
      </ng-icon>
      <span>
        {{ 'i18n.DefinePage.selectTypology' | translate }}
      </span>
    </div>
    <div class="flex w-full flex-col justify-start overflow-y-scroll">
      <button
        *ngFor="let t of typologies"
        class="flex flex-row items-center justify-between border-b border-gray-200 p-4"
        [class.bg-blue-100]="typology === t"
        (click)="$event.stopPropagation(); typology === t ? typology = undefined : typology = t; expanded = false">
        {{ t.label | translate }}
      </button>
    </div>
  </div>
</ng-container>

<mat-menu
  #typologyMenu="matMenu"
  class="context-menu">
  <button
    *ngFor="let t of typologies"
    mat-menu-item
    [class.!bg-blue-100]="t.id === typology?.id"
    (click)="writeValue(t)">
    <div class="flex flex-row items-center justify-between">
      <ng-icon
        *ngIf="t.id === typology?.id"
        class="mr-1"
        name="heroCheck">
      </ng-icon>
      <span>{{ t.label | translate }}</span>
    </div>
  </button>
</mat-menu>
