import { NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroLockClosed } from '@ng-icons/heroicons/outline';
import { TranslateModule } from '@ngx-translate/core';

import { UITypology, typologies } from '../../../core/utils/typology';

@Component({
  selector: 'app-typology-bottom-sheet',
  templateUrl: './typology-bottom-sheet.component.html',
  styleUrls: ['./typology-bottom-sheet.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgIconComponent,
    NgIf,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroLockClosed,
    }),
  ],
})

export class TypologyBottomSheetComponent {
  readonly bottomSheetRef = inject(MatBottomSheetRef<TypologyBottomSheetComponent>);
  readonly selected: UITypology = inject(MAT_BOTTOM_SHEET_DATA);

  readonly typologies = typologies;
}
