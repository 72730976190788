/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import { FormGroup } from '@angular/forms';
import { intervalToDuration } from 'date-fns';

export const zeroPad = (num: number) => String(num).padStart(2, '0');

export const transformSecToTimeSpan = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  return `${zeroPad(duration.hours ?? 0)}:${zeroPad(duration.minutes ?? 0)}:${zeroPad(duration.seconds ?? 0)}`;
};

export const transformTimeSpanToSec = (timeSpan: string) => {
  const hms = timeSpan.split(':');
  const seconds = (+hms[0]) * 60 * 60 + (+hms[1]) * 60 + (+hms[2]);
  return seconds;
};

export const transformTimespanToHoursMinutes = (timeSpan: string) => {
  const [daysAndHoursStr, minutesStr] = timeSpan.split(':');
  const minutes = Number(minutesStr);
  const daysAndHours = daysAndHoursStr.split('.').map(Number);

  const hours = daysAndHours.length > 1
    ? ((daysAndHours[0] * 24) + daysAndHours[1])
    : daysAndHours[0];

  return `${zeroPad(+hours ?? 0)}:${zeroPad(minutes ?? 0)}`;
};

export const transformHoursMinutesSecondsToTimespan = (hoursMinutesSeconds: string) => {
  let [hours, minutes, seconds] = hoursMinutesSeconds.split(':').map(Number);
  let daysStr = '';

  if (hours >= 24) {
    daysStr = `${Math.floor(hours / 24).toString()}.`;
    hours %= 24;
  }

  return `${daysStr}${zeroPad(hours ?? 0)}:${zeroPad(minutes ?? 0)}:${zeroPad(seconds ?? 0)}`;
};

export const addTimes = (startTime: string, endTime: string) => {
  if (startTime.includes('.')) {
    startTime = transformTimespanToHoursMinutes(startTime);
  }

  if (endTime.includes('.')) {
    endTime = transformTimespanToHoursMinutes(endTime);
  }

  const times = [0, 0, 0];
  const max = times.length;

  const a = (startTime || '').split(':');
  const b = (endTime || '').split(':');
  const newA = [];
  const newB = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < max; i++) {
    const aNum = parseInt(a[i], 10);
    const bNum = parseInt(b[i], 10);
    newA[i] = Number.isNaN(aNum) ? 0 : aNum;
    newB[i] = Number.isNaN(bNum) ? 0 : bNum;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < max; i++) {
    times[i] = newA[i] + newB[i];
  }

  let hours = times[0];
  let minutes = times[1];
  let seconds = times[2];

  if (seconds >= 60) {
    // eslint-disable-next-line no-bitwise
    const m = (seconds / 60) << 0;
    minutes += m;
    seconds -= 60 * m;
  }

  if (minutes >= 60) {
    // eslint-disable-next-line no-bitwise
    const h = (minutes / 60) << 0;
    hours += h;
    minutes -= 60 * h;
  }

  // eslint-disable-next-line prefer-template
  return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
};

export const addMinutesToForm = (step: number, form: FormGroup, hoursAs: string, minutesAs: string) => {
  let hoursValue = parseInt(form.value[hoursAs], 10) || 0;
  let minutesValue = parseInt(form.value[minutesAs], 10) || 0;

  minutesValue += step;

  if (minutesValue < 0) {
    const borrowedHours = Math.ceil(Math.abs(minutesValue) / 60);
    hoursValue = Math.max(0, hoursValue - borrowedHours);
    minutesValue = 60 - (Math.abs(minutesValue) % 60);
  } else if (minutesValue >= 60) {
    hoursValue += Math.floor(minutesValue / 60);
    minutesValue %= 60;
  }

  form.patchValue({
    [hoursAs]: hoursValue.toString().padStart(2, '0'),
    [minutesAs]: minutesValue.toString().padStart(2, '0'),
  });
};

export const enforceFullTimeFormat = (
  ev: Event,
  input: HTMLInputElement,
  previous?: string,
  form?: FormGroup,
  timestringAs?: string,
) => {
  if (ev instanceof KeyboardEvent && ev.key.length === 1 && !/[0-9]/.test(ev.key)) {
    ev.preventDefault();
    ev.stopPropagation();
    return;
  }

  if (ev instanceof KeyboardEvent && (input.value.length === 2 || input.value.length === 5) && ev.key.length === 1) {
    // eslint-disable-next-line no-param-reassign
    input.value += ':';
  }

  if (!timestringAs) {
    return;
  }

  if (ev instanceof FocusEvent
    && !/[0-9][0-9]:[0-5][0-9]:[0-5][0-9]/.test(form?.value?.[timestringAs] ?? '')) {
    form?.patchValue({ [timestringAs]: previous ?? '00:00:00' });
  }
};

// Do not allow the user to type something other than numbers or using functional keys in the input
export const enforceHours = (ev: Event, form: FormGroup, hoursField: string) => {
  if (ev instanceof KeyboardEvent && ev.key.length === 1 && !/[0-9]/.test(ev.key)) {
    ev.preventDefault();
    ev.stopPropagation();
    return;
  }

  if (!form.value[hoursField] || form.value[hoursField].length === 1) {
    return;
  }

  if (ev instanceof FocusEvent && !/[0-9][0-9]/.test(form.value[hoursField])) {
    form.patchValue({ [hoursField]: '00' });
  }
};

// Do not allow the user to type something other than numbers or using functional keys in the input
export const enforceMinutes = (ev: Event, form: FormGroup, minutesField: string) => {
  if (ev instanceof KeyboardEvent && ev.key.length === 1 && !/[0-9]/.test(ev.key)) {
    ev.preventDefault();
    ev.stopPropagation();
    return;
  }

  if (!form.value[minutesField]
    || form.value[minutesField].length === 1) {
    return;
  }

  if (ev instanceof FocusEvent && !/[0-5][0-9]/.test(form.value[minutesField])) {
    form.patchValue({ [minutesField]: '00' });
  }
};

export const getHoursFromTimesSpan = (timeSpan?: string | null) => timeSpan?.substring(0, 2).padStart(2, '0');
export const getMinutesFromTimesSpan = (timeSpan?: string | null) => timeSpan?.substring(3, 5).padStart(2, '0');

export const sumTimes = (times: string[]) => {
  let totalHours = 0;
  let totalMinutes = 0;
  let totalSeconds = 0;

  for (let time of times) {
    time = `${transformTimespanToHoursMinutes(time)}:00`;
    const [hours, minutes, seconds] = time.split(':').map(Number);
    totalHours += hours;
    totalMinutes += minutes;
    totalSeconds += seconds;
  }

  totalMinutes += Math.floor(totalSeconds / 60);
  totalSeconds %= 60;

  totalHours += Math.floor(totalMinutes / 60);
  totalMinutes %= 60;

  const formattedHours = String(totalHours).padStart(2, '0');
  const formattedMinutes = String(totalMinutes).padStart(2, '0');
  const formattedSeconds = String(totalSeconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const timeToMinutes = (time: string): number => {
  if (time.includes('.')) {
    time = transformTimespanToHoursMinutes(time);
  }
  const [hours, minutes] = time.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes;
  return totalMinutes;
};

export const compareTimes = (time1: string, time2: string) => {
  const span1 = timeToMinutes(time1);
  const span2 = timeToMinutes(time2);

  return span1 > span2;
};

export const subtractTimes = (time1: string, time2: string) => {
  const m1 = timeToMinutes(time1);
  const m2 = timeToMinutes(time2);

  let diffMinutes = m1 - m2;
  const diffHours = Math.floor(diffMinutes / 60);

  diffMinutes %= 60;

  const formattedHours = String(diffHours).padStart(2, '0');
  const formattedMinutes = String(diffMinutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:00`;
};

export const minutesToTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(remainingMinutes.toFixed(0)).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

export const retrieveTimeDeltaPercentage = (now: string, last: string) => {
  if (last === '00:00:00') {
    return 0;
  }

  const differenceMinutes = timeToMinutes(now) - timeToMinutes(last);
  const percentageDifference = (differenceMinutes / Math.max(timeToMinutes(last), 1)) * 100;

  return percentageDifference;
};

export const retrieveTimeOnTotalPercentage = (
  time: string,
  total: string,
) => (total !== '00:00:00'
  ? +((timeToMinutes(time) / timeToMinutes(total)) * 100).toPrecision(2)
  : 0);
