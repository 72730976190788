import {
  AsyncPipe,
  DatePipe,
  NgClass,
  NgIf,
} from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { Router, RouterOutlet } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';
import { UsersService } from 'ngx-atred-api-connectors';
import {
  firstValueFrom, map, switchMap,
} from 'rxjs';

import { AuthService } from '../../../core/services';
import { MeasureOverlayService } from '../../../core/services/measure-overlay.service';
import { brandActivityTime } from '../../../shared';
import { UserIdentityComponent } from '../../../shared/components/user-identity/user-identity.component';
import { QuickMeasureBottomSheetComponent } from '../quick-measure-bottom-sheet/quick-measure-bottom-sheet.component';
import { SidenavComponent } from '../sidenav/sidenav.component';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    MatBottomSheetModule,
    NgClass,
    NgIconComponent,
    NgIf,
    RouterOutlet,
    SidenavComponent,
    TranslateModule,
    UserIdentityComponent,
  ],
  providers: [
    provideIcons({
      brandActivityTime,
    }),
  ],
})
export class RootComponent {
  readonly today = new Date(Date.now());
  readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UsersService);
  private readonly bottomSheet = inject(MatBottomSheet);

  private readonly measureOverlayService = inject(MeasureOverlayService);
  readonly hasLiveMeasure$ = this.measureOverlayService.data$.pipe(
    map((d) => !!d),
  );

  readonly user$ = this.authService.refreshUser$.pipe(
    switchMap(() => this.userService.v0UsersMeGet()),
    map((me) => ({
      id: me.id,
      firstName: me.firstName,
      lastName: me.lastName,
      hasAvatar: me.hasAvatar,
    })),
  );

  async openQuickMeasureBottomSheet() {
    const quickMeasure = this.bottomSheet.open(QuickMeasureBottomSheetComponent, {
      panelClass: 'quick-measure-panel',
    });
    const res = await firstValueFrom(quickMeasure.afterDismissed());
    if (res) {
      this.measureOverlayService.show();
      const data = this.measureOverlayService.data$.value;
      this.measureOverlayService.data$.next(data);
    }
  }
}
