import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RootComponent } from './components/root/root.component';

const routes: Routes = [{
  path: '',
  component: RootComponent,
  children: [
    {
      path: 'login',
      loadComponent: () => import('./components/login-page/login-page.component')
        .then((c) => c.LoginPageComponent),
    },
    {
      path: 'sign-up',
      loadComponent: () => import('./components/sign-up-page/sign-up-page.component')
        .then((c) => c.SignUpPageComponent),
    },
    {
      path: 'recover',
      loadComponent: () => import('./components/recover-page/recover-page.component')
        .then((c) => c.RecoverPageComponent),
    },
    {
      path: 'reset-password',
      loadComponent: () => import('./components/reset-password-page/reset-password-page.component')
        .then((c) => c.ResetPasswordPageComponent),
    },
  ],
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {
}
