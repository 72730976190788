<div
  *ngIf="visibility === Visibility.Public"
  class="flex cursor-pointer items-center rounded-xl border border-core-accent px-1 py-[2px] text-xs font-semibold text-core-accent"
  matTooltipPosition="left"
  [ngClass]="{
      'pointer-events-none touch-none': readOnly
    }"
  [matTooltip]="'i18n.Common.makePrivate' | translate"
  (click)="writeValue(Visibility.Private)">
  <ng-icon
    name="heroBriefcase"
    class="mr-[2px]">
  </ng-icon>
  {{ 'i18n.Common.workingSingular' | translate }}
</div>

<div
  *ngIf="visibility === Visibility.Private"
  class="flex cursor-pointer items-center rounded-xl border border-orange-800 px-1 py-[2px] text-xs font-semibold text-orange-800"
  matTooltipPosition="left"
  [matTooltip]="'i18n.Common.makePublic' | translate"
  (click)="writeValue(Visibility.Public)">
  <ng-icon
    name="heroUser"
    class="mr-[2px]">
  </ng-icon>
  {{ 'i18n.Common.privateSingular' | translate }}
</div>
