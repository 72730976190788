<div class="flex w-full flex-col items-start">
  <form
    class="flex w-full flex-col items-center gap-4 p-4"
    [formGroup]="newCheckpointForm"
    (ngSubmit)="createCheckpoint()">
    <div class="flex w-full flex-col items-start gap-2">
      <h1 class="text-2xl !font-extrabold">
        {{ 'i18n.NewCheckpoint.title' | translate }}
      </h1>
      <span class="font-bold">
        {{ 'i18n.Common.givenName' | translate }}
      </span>
      <div
        class="flex w-full flex-row items-center gap-2 rounded-lg border border-gray-200 p-3 text-gray-900"
        [ngClass]="{
          'opacity-40 cursor-not-allowed' : isLoading
        }">

        <input
          class="w-full bg-transparent"
          autocomplete="on"
          formControlName="name"
          type="text"
          [placeholder]="'es. ATRED1 - Checkpoint 2023-05-22' | translate"
          [readonly]="isLoading">
      </div>

      <!-- FIXME: Description actually is not exposed from the API model -->
      <!-- <span class="mt-2 font-bold">
        {{ 'i18n.Common.description' | translate }}
      </span>
      <div
        class="w-full flex flex-row items-center gap-2 text-gray-900 p-3 border border-gray-200 rounded-lg"
        [ngClass]="{
        'opacity-40 cursor-not-allowed' : isLoading
        }">

        <input
          class="bg-transparent w-full"
          autocomplete="on"
          formControlName="description"
          type="text"
          [placeholder]="'Descrizione opzionale' | translate"
          [readonly]="isLoading">
      </div> -->

      <div class="flex w-full flex-row gap-2 md:mx-auto md:w-fit">
        <button
          class="w-full rounded-full border border-gray-400 bg-white px-6 py-2 text-sm text-gray-400 hover:opacity-50 md:w-fit"
          type="button"
          [ngClass]="{
            'opacity-40 cursor-not-allowed' : isLoading
          }"
          [disabled]="isLoading"
          (click)="matDialogRef.close()">
          {{ 'i18n.Common.cancel' | translate }}
        </button>
        <button
          class="w-full rounded-full bg-core-accent px-6 py-2 text-sm text-white md:w-fit"
          [ngClass]="{
            'opacity-40 cursor-not-allowed' : newCheckpointForm.invalid || isLoading,
            'hover:opacity-90': newCheckpointForm.valid
          }"
          [disabled]="newCheckpointForm.invalid">
          {{ 'i18n.Common.create' | translate }}
        </button>
      </div>

    </div>
  </form>
</div>
