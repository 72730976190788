<div class="flex w-full flex-col items-start p-4">
  <h1>{{ data.title }}</h1>

  <p
    *ngIf="data.description"
    class="mb-3">
    {{ data.description }}
  </p>

  <div class="mt-4 flex w-full justify-center">
    <button
      *ngIf="isAlertDialog(data)"
      mat-dialog-close
      class="w-full rounded-full bg-core-accent px-6 py-2 text-sm text-white md:w-fit">
      {{ data.dismissLabel }}
    </button>

    <button
      *ngIf="isConfirmDialog(data)"
      class="mr-2 w-full rounded-full border border-gray-400 bg-white px-6 py-2 text-sm text-gray-400 hover:opacity-50 md:w-fit"
      type="button"
      [mat-dialog-close]="false">
      {{ data.secondaryLabel }}
    </button>

    <button
      *ngIf="isConfirmDialog(data)"
      class="w-full rounded-full bg-core-accent px-6 py-2 text-sm text-white md:w-fit"
      data-test="confirmation-dialog-primary-btn"
      [mat-dialog-close]="true">
      {{ data.primaryLabel }}
    </button>
  </div>
</div>
