import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentState, SheetItem } from 'ngx-atred-api-connectors';
import { BehaviorSubject } from 'rxjs';

import { DocumentListItemComponent } from './document-list-item/document-list-item.component';
import { trackById } from '../../../../core/utils';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    DocumentListItemComponent,
    NgClass,
    NgFor,
    NgIf,
    RouterModule,
    TranslateModule,
  ],
})
export class DocumentListComponent {
  private readonly documents$ = new BehaviorSubject<SheetItem[] | null>(null);
  @Input() set sheets(value: SheetItem[] | null) {
    this.documents$.next(value);
  }
  get sheets(): SheetItem[] | null {
    return this.documents$.value;
  }

  @Output() readonly remove = new EventEmitter<SheetItem>();
  @Output() readonly edit = new EventEmitter<SheetItem>();
  @Output() readonly share = new EventEmitter<SheetItem>();
  @Output() readonly toggleState = new EventEmitter<SheetItem>();

  @Input() selected?: SheetItem;
  @Output() readonly selectedChange = new EventEmitter<SheetItem>();

  readonly DocumentState = DocumentState;
  readonly trackById = trackById;
}
