<div
  class="flex w-full cursor-pointer flex-row items-center gap-6 rounded-lg bg-white/30 px-4 py-3 text-gray-900 transition-all hover:bg-white/60 hover:text-core-accent md:px-6 md:py-4"
  [routerLink]="link"
  [routerLinkActive]="['!bg-white/90', '!hover:bg-white/90', '!text-core-accent']"
  [ngClass]="{
    '!cursor-not-allowed hover:bg-brand-light opacity-50': !link
  }">
  <ng-icon
    class="text-xl md:text-2xl"
    [name]="icon"></ng-icon>
  <p class="!m-0 grow text-xl capitalize-first md:text-2xl">{{ label | translate }}</p>
  <div
    *ngIf="count"
    class="bg-white/3 flex h-8 w-8 flex-row items-center justify-center rounded-full"
    [routerLinkActive]="['!bg-blue-100']">
    {{ count }}
  </div>
</div>
