<div
  class="mx-auto flex w-full max-w-xl grow flex-row items-center gap-1"
  [class.gap-4]="size === AvatarSize.Large">
  <ng-container *ngIf="user; else loadingUser">
    <div
      class="flex flex-col"
      [ngClass]="{
        'w-16': size === AvatarSize.Large,
        'w-5': size === AvatarSize.Small,
        'w-4': size === AvatarSize.ExtraSmall
      }">
      <img
        #avatar
        class="rounded-full object-cover"
        [src]="avatarUrl ?? './assets/placeholders/default-avatar.svg'"
        (error)="avatar.src = './assets/placeholders/default-avatar.svg'">
    </div>
    <div class="flex flex-col items-start gap-1">
      <span
        class="line-clamp-2 font-semibold"
        [ngClass]="{
          'text-2xl': size === AvatarSize.Large,
          'text-xs': size === AvatarSize.ExtraSmall,
          'truncate': size === AvatarSize.ExtraSmall
        }">
        {{ user.firstName }} {{ user.lastName }}
      </span>
      <ng-content></ng-content>
    </div>
  </ng-container>
</div>

<ng-template #loadingUser>
  <div class="flex flex-col">
    <div
      class="animate-pulse rounded-full bg-black/10"
      [ngClass]="{
      'w-20 h-20': size === AvatarSize.Large,
      'w-4 h-4': size === AvatarSize.ExtraSmall
      }"></div>
  </div>
  <div class="flex flex-col items-start gap-1">
    <div
      class="h-9 w-52 animate-pulse rounded-md bg-black/10"
      [ngClass]="{
      'w-52 h-9': size === AvatarSize.Large,
      'w-12 h-4': size === AvatarSize.ExtraSmall
      }"></div>
    <div
      *ngIf="size === AvatarSize.Large,"
      class="h-6 w-40 animate-pulse rounded-lg bg-black/10"></div>
  </div>
</ng-template>
