export const trackById = <T extends { id?: string }>(_: number, item: T) => item.id;
export const trackByIndex = (index: number) => index;

/**
 * Throws an Error with the given message.
 *
 * Use to throw an exception from an expression context.
 *
 * Example:
 * ```
 * const value = this.getValue() ?? unreachable(`value must be present at this point`);
 * ```
 */
export const unreachable = (reason?: string): never => {
  throw new Error(reason ?? 'unreachable code reached');
};

/**
 *
 * https://github.com/microsoft/TypeScript/issues/34523
 */
export function required<T>(value: T, reason?: string): asserts value is NonNullable<T> {
  if (value === null || value === undefined) { unreachable(reason ?? 'value is null'); }
}
