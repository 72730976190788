import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

import { DialogData } from './models';
import { isAlertDialog, isConfirmDialog } from './utils';

@Component({
  selector: 'app-generic-dialog-component',
  templateUrl: 'generic-dialog.component.html',
  styles: [
  ],
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class GenericDialogComponent {
  readonly isConfirmDialog = isConfirmDialog;
  readonly isAlertDialog = isAlertDialog;

  readonly dialog = inject(MatDialog);
  readonly data: DialogData = inject(MAT_DIALOG_DATA);
}
