<div class="flex w-full flex-col items-start">
  <div class="flex w-full flex-col items-start p-4">
    <h1 class="text-2xl !font-extrabold">
      {{ 'i18n.ListCheckpoints.title' | translate }}
    </h1>

    <ng-container *ngIf="checkpoints$ | async as checkpoints">
      <p>{{ 'i18n.ListCheckpoints.description' | translate }}</p>
      <div
        *ngIf="checkpoints.length; else noCheckpoints"
        class="mb-6 w-full rounded-lg border border-gray-200 text-gray-900">
        <div
          *ngFor="let checkpoint of checkpoints; let last = last"
          class="flex flex-row items-center p-3"
          [class.border-b]="!last">
          <input
            class="mr-2 cursor-pointer"
            type="radio"
            name="selected-checkpoint"
            [id]="checkpoint.id"
            [value]="checkpoint.id"
            (click)="selectedCheckpoint = checkpoint">
          <label
            class="cursor-pointer"
            [for]="checkpoint.id">
            {{ checkpoint.name }}
          </label>
        </div>
      </div>
    </ng-container>

    <ng-template #noCheckpoints>
      <div class="w-full pb-4 text-center text-base">
        {{ 'i18n.ListDocumentCheckpointsDialog.empty' | translate }}
      </div>
    </ng-template>

    <div class="flex w-full flex-row gap-2 md:mx-auto md:w-fit">
      <button
        class="mr-2 w-full rounded-full border border-gray-400 bg-white px-6 py-2 text-sm text-gray-400 hover:opacity-50 md:w-fit"
        (click)="matDialogRef.close()">
        {{ 'i18n.Common.cancel' | translate }}
      </button>

      <button
        class="w-full rounded-full bg-core-accent px-6 py-2 text-sm text-white md:w-fit"
        [disabled]="!selectedCheckpoint"
        [ngClass]="{
          '!bg-core-accent/40 cursor-not-allowed' : !selectedCheckpoint
        }"
        [routerLink]="['/document', sheetId, 'checkpoint', selectedCheckpoint?.id, 'define']"
        (click)="matDialogRef.close()">
        {{ 'i18n.Common.select' | translate }}
      </button>
    </div>
  </div>
</div>
