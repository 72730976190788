/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  Component,
  Input,
  forwardRef,
  inject,
} from '@angular/core';
import {
  ControlValueAccessor, NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroCheck,
} from '@ng-icons/heroicons/outline';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { UITypology, typologies } from '../../../core/utils';
import { TypologyBottomSheetComponent } from '../typology-bottom-sheet/typology-bottom-sheet.component';

@Component({
  selector: 'app-activity-typology',
  templateUrl: './activity-typology.component.html',
  styleUrls: ['./activity-typology.component.scss'],
  standalone: true,
  imports: [
    MatMenuModule,
    MatTooltipModule,
    NgClass,
    NgFor,
    NgIconComponent,
    NgIf,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroCheck,
    }),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActivityTypologyComponent),
      multi: true,
    },
  ],
})

export class ActivityTypologyComponent implements ControlValueAccessor {
  @Input() readOnly = true;
  @Input() editing = false;
  private readonly bottomSheet = inject(MatBottomSheet);

  private readonly typology$ = new BehaviorSubject<UITypology | undefined>(undefined);
  @Input() set typology(val: UITypology | undefined) {
    this.typology$.next(val);
    this.onChange(val);
    this.onTouch(val);
  }
  get typology() {
    return this.typology$.value;
  }

  readonly typologies = typologies;
  expanded = false;

  onChange: any = () => { };
  onTouch: any = () => { };

  writeValue(value: UITypology) {
    this.typology = value;
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  async openTypologyBottomSheet() {
    const res = await firstValueFrom(this.bottomSheet.open(TypologyBottomSheetComponent, {
      data: this.typology,
    }).afterDismissed());

    if (res) {
      this.writeValue(res);
    }
  }
}
