<div class="flex w-full flex-col items-start">
  <form
    class="flex w-full flex-col items-center gap-4 p-4"
    [formGroup]="upsertLegacyNoteForm"
    (ngSubmit)="upsertNote()">
    <div class="flex w-full flex-col items-start gap-2">
      <h1 class="text-2xl !font-extrabold">{{ 'i18n.UpsertActionNotes.title' | translate }}</h1>

      <div class="flex w-full flex-row items-center gap-2 rounded-lg border border-gray-200 p-3 text-gray-900">
        <!-- TODO: Use a textarea (needed to be styled) -->
        <input
          class="w-full bg-transparent"
          autocomplete="on"
          formControlName="note"
          type="text"
          [ngClass]="{
            'pointer-events-none touch-none': data.readOnly
          }"
          [readOnly]="data.readOnly"
          [placeholder]="'i18n.UpsertActionNotes.description' | translate">
      </div>

      <div class="flex w-full flex-row gap-2 md:mx-auto md:w-fit">
        <button
          class="w-full rounded-full border border-gray-400 bg-white px-6 py-2 text-sm text-gray-400 hover:opacity-50 md:w-fit"
          type="button"
          (click)="matDialogRef.close()">
          {{ 'i18n.Common.cancel' | translate }}
        </button>
        <button
          *ngIf="!data.readOnly"
          class="w-full rounded-full bg-core-accent px-6 py-2 text-sm text-white md:w-fit">
          {{ 'i18n.Common.save' | translate }}
        </button>
      </div>

    </div>
  </form>
</div>
