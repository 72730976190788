/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  NgClass,
  NgIf,
} from '@angular/common';
import {
  Component,
  Input,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor, NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroBriefcase,
  heroUser,
} from '@ng-icons/heroicons/outline';
import { TranslateModule } from '@ngx-translate/core';
import {
  Visibility,
} from 'ngx-atred-api-connectors';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-activity-visibility',
  templateUrl: './activity-visibility.component.html',
  styleUrls: ['./activity-visibility.component.scss'],
  standalone: true,
  imports: [
    MatMenuModule,
    MatTooltipModule,
    NgClass,
    NgIconComponent,
    NgIf,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroBriefcase,
      heroUser,
    }),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActivityVisibilityComponent),
      multi: true,
    },
  ],
})

export class ActivityVisibilityComponent implements ControlValueAccessor {
  @Input() readOnly = true;

  private readonly visibility$ = new BehaviorSubject<Visibility | undefined>(undefined);
  @Input() set visibility(val: Visibility | undefined) {
    this.visibility$.next(val);
    this.onChange(val);
    this.onTouch(val);
  }
  get visibility() {
    return this.visibility$.value;
  }

  readonly Visibility = Visibility;

  onChange: any = () => { };
  onTouch: any = () => { };

  writeValue(value: Visibility) {
    this.visibility = value;
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
