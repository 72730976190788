import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';

import { GenericDialogComponent } from './generic.dialog.component';
import { AlertDialogData, ConfirmDialogData } from './models';

@Injectable({
  providedIn: 'root',
})
export class GenericDialogService {
  private readonly dialog = inject(MatDialog);

  async confirm({ classes, ...data }: ConfirmDialogData): Promise<boolean | undefined> {
    try {
      const res = await firstValueFrom(
        this.dialog.open(
          GenericDialogComponent,
          {
            data,
            panelClass: ['confirm'].concat(classes || []),
            width: '400px',
          },
        ).afterClosed(),
      );
      return !!res;
    } catch {
      return undefined;
    }
  }

  async alert({ classes, ...data }: AlertDialogData): Promise<void> {
    try {
      const res = await firstValueFrom(
        this.dialog.open(
          GenericDialogComponent,
          {
            data,
            panelClass: ['alert'].concat(classes || []),
            width: '400px',
          },
        ).afterClosed(),
      );
      return res;
    } catch {
      return undefined;
    }
  }
}
