import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { format } from 'date-fns';
import { CreateCheckpointRequest, Sheet, SheetsService } from 'ngx-atred-api-connectors';
import { firstValueFrom } from 'rxjs';

import { required } from '../../../core/utils';

@Component({
  selector: 'app-new-checkpoint-dialog',
  templateUrl: './new-checkpoint-dialog.component.html',
  styleUrls: ['./new-checkpoint-dialog.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class NewCheckpointDialogComponent {
  private readonly data: { sheet: Sheet } = inject(MAT_DIALOG_DATA);
  private readonly formBuilder = inject(FormBuilder);
  private readonly sheetsApi = inject(SheetsService);
  readonly matDialogRef = inject(MatDialogRef);

  readonly newCheckpointForm = this.formBuilder.group({
    name: this.formBuilder.nonNullable.control(
      `ATRED - Checkpoint ${format(new Date(), 'yyyy-MM-dd')}`,
      [Validators.required],
    ),
    description: [''],
  });

  isLoading = false;

  // TODO: Move it on a UI service if reused from the bottomSheet on mobile
  // TODO: Add a loading indicator?
  async createCheckpoint() {
    required(this.data.sheet.id);

    if (this.newCheckpointForm.invalid) {
      this.newCheckpointForm.markAllAsTouched();
      return undefined;
    }
    const { name } = this.newCheckpointForm.getRawValue();

    const body: CreateCheckpointRequest = { name };

    const checkpoint = await firstValueFrom(
      this.sheetsApi.v0SheetsSheetIdCheckpointsPost({ sheetId: this.data.sheet.id, body }),
    );

    return this.matDialogRef.close(checkpoint);
  }
}
