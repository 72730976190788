import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RootComponent } from './components/root/root.component';

const routes: Routes = [{
  path: '',
  component: RootComponent,
  children: [
    {
      path: '',
      loadComponent: () => import('./components/dashboard-tips/dashboard-tips.component')
        .then((c) => c.DashboardTipsComponent),
    },
    {
      path: 'my-documents',
      loadComponent: () => import('./components/my-documents-page/my-documents-page.component')
        .then((c) => c.MyDocumentsPageComponent),
    },
    {
      path: 'shared-with-me',
      loadComponent: () => import('./components/shared-with-me-page/shared-with-me-page.component')
        .then((c) => c.SharedWithMePageComponent),
    },
    {
      path: 'groups',
      loadComponent: () => import('./components/groups-page/groups-page.component')
        .then((c) => c.GroupsPageComponent),
    },
    {
      path: 'advices',
      loadComponent: () => import('./components/advices-page/advices-page.component')
        .then((c) => c.AdvicesPageComponent),
    },
    {
      path: 'settings',
      loadComponent: () => import('./components/settings-page/settings-page.component')
        .then((c) => c.SettingsPageComponent),
    },
  ],
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {
}
