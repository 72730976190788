<div class="relative box-border flex flex-row items-center gap-3 rounded-lg border border-gray-200 bg-white p-3 text-2xl font-light tracking-wide text-gray-400">
  <ng-icon
    class="text-2xl"
    name="heroMagnifyingGlass"></ng-icon>
  <input
    class="grow bg-transparent text-xl"
    matInput
    [placeholder]="'i18n.Common.search' | translate"
    [ngModel]="query"
    (ngModelChange)="queryChange.emit($event || undefined)">
  <ng-icon
    class="absolute right-4 cursor-pointer text-2xl"
    name="heroXMark"
    [class.invisible]="!query"
    (click)="queryChange.next(undefined)"></ng-icon>
  <ng-content></ng-content>
</div>
