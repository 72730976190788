/* eslint-disable import/no-cycle */
import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  Component, OnInit, inject,
} from '@angular/core';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroPauseSolid, heroPlayPauseSolid, heroPlaySolid } from '@ng-icons/heroicons/solid';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { INSTANT_MEASURE_DATA, MeasureOverlayService } from '../../../core/services/measure-overlay.service';
import { Measure, MeasureService } from '../../../core/services/measure.service';
import { required } from '../../../core/utils';
import {
  ActivityMeasureBottomSheetComponent,
} from '../activity-measure-bottom-sheet/activity-measure-bottom-sheet.component';

@Component({
  selector: 'app-current-measure-label',
  templateUrl: './current-measure-label.component.html',
  styleUrls: ['./current-measure-label.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    MatBottomSheetModule,
    NgClass,
    NgFor,
    NgIconComponent,
    NgIf,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroPauseSolid,
      heroPlayPauseSolid,
      heroPlaySolid,
    }),
  ],
})
export class CurrentMeasureLabelComponent implements OnInit {
  readonly data = inject(INSTANT_MEASURE_DATA);
  private readonly bottomSheet = inject(MatBottomSheet);
  private readonly measureService = inject(MeasureService);
  private readonly measureOverlayService = inject(MeasureOverlayService);

  readonly measure$ = new BehaviorSubject<Measure | undefined>(undefined);

  async ngOnInit() {
    required(this.data.activity);
    required(this.data.sheet);
    const measure = this.measureService.getMeasure(this.data.activity.id, this.data.sheet?.id);
    await this.measureService.startTimer(measure.id, measure.sheetId);
    this.measure$.next(measure);
  }

  async openActivityMeasureBottomSheet() {
    const quickMeasure = this.bottomSheet.open(ActivityMeasureBottomSheetComponent, {
      panelClass: 'quick-measure-panel',
      data: { ...this.data, measure: this.measure$.value },
    });
    const res = await firstValueFrom(quickMeasure.afterDismissed());

    if (res) {
      this.measureOverlayService.hide();
      this.measure$.next(undefined);
    }
  }
}
