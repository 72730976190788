/* eslint-disable import/no-cycle */
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroPlus, heroTrash } from '@ng-icons/heroicons/outline';
import { heroPauseSolid, heroPlayPauseSolid, heroPlaySolid } from '@ng-icons/heroicons/solid';
import { TranslateModule } from '@ngx-translate/core';
import { Activity, SheetsService as SheetApi } from 'ngx-atred-api-connectors';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { MeasurementClockComponent } from './measurement-clock/measurement-clock.component';
import { FormatTimePipe } from '../../../core/pipes/format-time.pipe';
import { ToHoursMinutesPipe } from '../../../core/pipes/to-hours-minutes.pipe';
import { InstantMeasurement } from '../../../core/services/measure-overlay.service';
import { Measure, MeasureService } from '../../../core/services/measure.service';
import { SheetsService } from '../../../core/services/sheets.service';
import { required, retrieveMeasuredTimesSum } from '../../../core/utils';
import { ActiveSheetService } from '../../../document/services/active-sheet.service';
import {
  brandActivityTime, brandFlag, brandMeasure, brandTime,
} from '../../icons';

@Component({
  selector: 'app-activity-measure-bottom-sheet',
  templateUrl: './activity-measure-bottom-sheet.component.html',
  styleUrls: ['./activity-measure-bottom-sheet.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    FormatTimePipe,
    MeasurementClockComponent,
    NgIconComponent,
    NgIf,
    ToHoursMinutesPipe,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroPlus,
      heroTrash,
      heroPauseSolid,
      heroPlayPauseSolid,
      heroPlaySolid,
      brandFlag,
      brandActivityTime,
      brandMeasure,
      brandTime,
    }),
    ActiveSheetService,
  ],
})

export class ActivityMeasureBottomSheetComponent implements OnInit {
  readonly bottomSheetRef = inject(MatBottomSheetRef<ActivityMeasureBottomSheetComponent>);
  readonly data: InstantMeasurement = inject(MAT_BOTTOM_SHEET_DATA);
  readonly measureService = inject(MeasureService);

  readonly sheetsApi = inject(SheetApi);
  private readonly sheetsService = inject(SheetsService);

  readonly measure$ = new BehaviorSubject<Measure | undefined>(undefined);
  readonly state$ = this.data.measure?.state$;

  readonly sheet$ = this.sheetsApi.v0SheetsIdGet({ id: this.data.sheet?.id ?? '' });

  readonly activeWeek$ = this.sheet$.pipe(
    map((sheet) => sheet.document.activeWeek),
  );

  readonly retrieveMeasuredTimesSum = retrieveMeasuredTimesSum;

  toggleMeasure() {
    required(this.data.sheet?.id);
    required(this.data.measure);
    return this.measureService.toggleTimer(this.data.measure.id, this.data.sheet.id);
  }

  ngOnInit(): void {
    required(this.data.measure);
    required(this.data.sheet);
    const measure = this.measureService.getMeasure(this.data.measure.id, this.data.sheet.id);
    this.measure$.next(measure);
  }

  async commitMeasure(ownTime: string) {
    required(this.data.activity);
    required(this.data.measure);

    const updatedActivity: Activity = JSON.parse(JSON.stringify(this.data.activity));
    const activeWeek = await firstValueFrom(this.activeWeek$);
    const currentMeasure = updatedActivity?.measurements.find((m) => m.week === activeWeek);

    await this.measureService.confirmRealtimeMeasure(
      this.data.measure,
      this.data.activity,
      currentMeasure,
      ownTime,
      activeWeek,
    );

    await this.updateActivity(this.data.activity);
    this.bottomSheetRef.dismiss('done-measuring');
  }

  async discardMeasure() {
    required(this.data.measure);
    await this.measureService.discardRealtimeMeasure(this.data.measure);
    this.bottomSheetRef.dismiss('done-measuring');
  }

  async updateActivity(activity: Activity) {
    const sheet = await firstValueFrom(this.sheet$);

    if (!sheet) {
      return;
    }

    await this.sheetsService.updateActivity(sheet, activity);
  }
}
