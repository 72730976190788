import {
  AsyncPipe,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroCheck, heroMagnifyingGlass, heroPlus, heroXMark,
} from '@ng-icons/heroicons/outline';
import { TranslateModule } from '@ngx-translate/core';
import { Tag } from 'ngx-atred-api-connectors';
import {
  BehaviorSubject, combineLatest, map,
} from 'rxjs';

import { TagItemComponent } from './tag-item/tag-item.component';
import { SheetsService } from '../../../core/services';
import { required } from '../../../core/utils';
import { ActiveSheetService } from '../../../document/services/active-sheet.service';

const isSelected = (myTags?: Tag[], id?: string) => !!myTags?.map((t) => t.id).includes(id ?? '');

@Component({
  selector: 'app-tag-menu',
  templateUrl: './tag-menu.component.html',
  styleUrls: ['./tag-menu.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    NgIconComponent,
    TranslateModule,
    NgIf,
    NgFor,
    TagItemComponent,
  ],
  providers: [
    provideIcons({
      heroCheck,
      heroMagnifyingGlass,
      heroXMark,
      heroPlus,
    }),
  ],
})
export class TagMenuComponent {
  private readonly activeSheetService = inject(ActiveSheetService);
  private readonly sheetsService = inject(SheetsService);

  @Input() myTags?: Tag[] = [];
  @Output() readonly selection = new EventEmitter<Tag>();
  @Output() readonly edit = new EventEmitter<Tag>();

  readonly query$ = new BehaviorSubject('');
  get query() {
    return this.query$.value;
  }
  set query(value: string) {
    this.query$.next(value);
  }

  private readonly tags$ = this.activeSheetService.document$.pipe(
    map((document) => document.tags),
  );

  readonly tagsList$ = combineLatest([
    this.query$,
    this.tags$,
  ]).pipe(
    map(([query, list]) => list?.filter((item) => new RegExp(query ?? '.', 'i').test(item.name))),
  );

  isCreatingTag = false;

  readonly isSelected = isSelected;

  async addNewTag(tag: Tag) {
    if (!tag) {
      this.isCreatingTag = false;
      return;
    }

    let { sheet } = this.activeSheetService;
    required(sheet);

    sheet = await this.sheetsService.addTag(tag, sheet);

    this.activeSheetService.setActiveSheet(sheet);
    this.isCreatingTag = false;
  }
}
