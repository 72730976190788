import { NgClass, NgIf } from '@angular/common';
import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroMagnifyingGlass, heroXMark } from '@ng-icons/heroicons/outline';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    NgIconComponent,
    NgIf,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroMagnifyingGlass,
      heroXMark,
    }),
  ],
})
export class SearchBarComponent {
  @Input() query?: string = undefined;
  @Output() readonly queryChange = new EventEmitter<string | undefined>();
}
