<div class="mx-auto flex w-full max-w-xl flex-col items-center gap-2">
  <app-sidenav-link
    class="w-full"
    icon="heroDocument"
    label="i18n.Common.myDocuments"
    link="my-documents"
    [count]="documentCount$ | async"></app-sidenav-link>
  <app-sidenav-link
    class="w-full"
    icon="heroShare"
    label="i18n.Common.sharedWithMe"
    link="shared-with-me"
    [count]="sharedCount$ | async"></app-sidenav-link>
  <app-sidenav-link
    class="w-full"
    icon="heroUserGroup"
    label="i18n.Common.groups"
    link="groups"
    [count]="groupCount$ | async"></app-sidenav-link>
  <app-sidenav-link
    class="mt-8 w-full"
    icon="heroBellAlert"
    label="i18n.Common.notifications"></app-sidenav-link>
  <app-sidenav-link
    class="w-full"
    icon="heroLightBulb"
    label="i18n.Common.advices"
    [count]="adviceCount$ | async"></app-sidenav-link>
  <app-sidenav-link
    class="w-full"
    icon="heroCog"
    label="i18n.Common.settings"
    link="settings"></app-sidenav-link>
</div>
