/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/ban-types */
import {
  ApplicationRef,
  createComponent,
  inject,
  Injectable,
  InjectionToken,
  Injector,
  ViewRef,
} from '@angular/core';
import { Activity, SheetItem } from 'ngx-atred-api-connectors';
import {
  BehaviorSubject,
} from 'rxjs';

import { Measure } from './measure.service';
import {
  CurrentMeasureLabelComponent,
} from '../../shared/components/current-measure-label/current-measure-label.component';

export interface InstantMeasurement {
  activity?: Activity,
  measure?: Measure,
  sheet?: SheetItem,
}

export const INSTANT_MEASURE_DATA = new InjectionToken<InstantMeasurement>('INSTANT_MEASURE_DATA');

@Injectable({
  providedIn: 'root',
})
export class MeasureOverlayService {
  private readonly injector = inject(Injector);
  private readonly applicationRef = inject(ApplicationRef);

  // Retrieve app-root to apply class on `show()`
  private readonly applicationHost = document.getElementsByTagName('app-root').item(0);

  // Retrieve context to where to show CurrentMeasureLabelComponent
  private readonly host = document.getElementById('quick-measure');

  // Save state to dismiss on unsubscribe
  private viewRef?: ViewRef;

  // Use this to pass data to CurrentMeasureLabelComponent
  readonly data$ = new BehaviorSubject<InstantMeasurement | undefined>(undefined);
  private get data() {
    return this.data$.value;
  }

  show(): void {
    if (!this.host || !this.applicationHost) {
      return;
    }

    // Generate injector to pass data to the Component that we're going to instantiate
    const instantMeasureInjector = this.generateInjector();

    // Add `quick-measuring` CSS class to make room for the quick measurement label
    this.applicationHost.classList.add('quick-measuring');

    const hostElement = this.host.appendChild(document.createElement('div'));
    const componentRef = createComponent(CurrentMeasureLabelComponent, {
      elementInjector: instantMeasureInjector,
      environmentInjector: this.applicationRef.injector,
      hostElement,
    });

    this.viewRef = componentRef.hostView;

    // Attach the newly generated component to app's view
    this.applicationRef.attachView(componentRef.hostView);
  }

  hide(): void {
    if (!this.viewRef || !this.applicationHost) {
      return;
    }

    this.applicationRef.detachView(this.viewRef);
    this.applicationHost.classList.remove('quick-measuring');
    this.viewRef = undefined;
    this.data$.next(undefined);
  }

  private generateInjector() {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: INSTANT_MEASURE_DATA, useValue: this.data },
      ],
    });
  }
}
