import { NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenuModule } from '@angular/material/menu';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroPencil,
  heroTrash,
  heroShare,
  heroArchiveBox,
} from '@ng-icons/heroicons/outline';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentState } from 'ngx-atred-api-connectors';

@Component({
  selector: 'app-sheet-context-menu',
  templateUrl: './sheet-context-menu.component.html',
  styleUrls: ['./sheet-context-menu.component.scss'],
  standalone: true,
  imports: [
    MatMenuModule,
    NgIconComponent,
    NgIf,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroArchiveBox,
      heroPencil,
      heroShare,
      heroTrash,
    }),
  ],
})
export class SheetContextMenuComponent implements OnInit {
  private readonly data: { id: string, state: DocumentState } = inject(MAT_BOTTOM_SHEET_DATA);
  readonly bottomSheet = inject(MatBottomSheet);

  @Input() id?: string;
  @Input() state?: DocumentState;
  @Input() disableShare = true;

  @Output() readonly remove = new EventEmitter<string>();
  @Output() readonly share = new EventEmitter<string>();
  @Output() readonly edit = new EventEmitter<string>();
  @Output() readonly toggleState = new EventEmitter<string>();

  readonly DocumentState = DocumentState;

  ngOnInit() {
    if (this.data && this.data.id && this.data.state) {
      this.id = this.data.id;
      this.state = this.data.state;
    }
  }
}
