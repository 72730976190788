<div class="flex h-full w-full flex-col items-start justify-between bg-white py-2 font-light tracking-wide max-sm:rounded-lg">
  <span class="sticky top-0 z-30 w-full shrink-0 grow border-b border-gray-200 bg-white px-4 py-2 text-xl text-black max-sm:line-clamp-2">
    {{ 'i18n.DefinePage.selectTags' | translate }}
  </span>

  <app-tag-menu
    class="w-full"
    [myTags]="data"
    (edit)="bottomSheetRef.dismiss({ edit: $event })"
    (selection)="bottomSheetRef.dismiss($event)"></app-tag-menu>
</div>
