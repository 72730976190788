<ng-container *ngIf="activity">
  <div class="flex h-fit w-full flex-col items-start justify-between rounded-lg border border-gray-200 bg-white font-light tracking-wide text-gray-400">
    <div
      class="relative z-10 flex w-full shrink-0 grow flex-col justify-center rounded-lg bg-white px-3 py-2 pl-2"
      [class.pl-4]="activity.isGold">
      <div
        *ngIf="activity?.isGold"
        class="gold-title absolute left-0 top-0 flex h-full flex-row items-center justify-center rounded-tl-md bg-yellow-300 text-yellow-600">
        <span class="rotate-180 text-xs uppercase">{{ 'i18n.Common.gold' | translate }}</span>
        <ng-icon name="heroStarSolid"></ng-icon>
      </div>

      <div
        class="flex flex-row items-center justify-between"
        [class.ml-2]="activity.isGold">
        <span class="line-clamp-2 w-full text-lg text-black">{{ activity.name }}</span>
      </div>

      <div
        class="flex w-full flex-row flex-wrap items-center justify-start gap-1"
        [class.ml-2]="activity.isGold">
        <div
          *ngIf="activity.visibility === Visibility.Public"
          class="flex cursor-pointer items-center rounded-xl border border-core-accent px-1 py-[2px] text-xs font-semibold text-core-accent">
          <ng-icon
            name="heroBriefcase"
            class="mr-[2px]">
          </ng-icon>
          {{ 'i18n.Common.workingSingular' | translate }}
        </div>

        <div
          *ngIf="activity?.visibility === Visibility.Private"
          class="flex cursor-pointer items-center rounded-xl border border-orange-800 px-1 py-[2px] text-xs font-semibold text-orange-800">
          <ng-icon
            name="heroUser"
            class="mr-[2px]">
          </ng-icon>
          {{ 'i18n.Common.privateSingular' | translate }}
        </div>

        <div
          *ngIf="activity?.type !== DEFAULT_TYPOLOGY.id"
          class="shrink-0 rounded-xl border border-core-light px-1 py-[2px] text-xs font-semibold text-core-light">
          <span>{{ getTypeById(activity.type)?.label }}</span>
        </div>

        <div
          *ngFor="let tag of activity.tags; let first = first"
          class="w-fit shrink-0 rounded px-1 py-[2px] text-xs text-white"
          [style.backgroundColor]="tag.color"
          (click)="$event.stopPropagation()">
          {{ tag.name }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
