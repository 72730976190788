import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { SidenavComponent } from './components/sidenav/sidenav.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { UserIdentityComponent } from '../shared/components/user-identity/user-identity.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SidenavComponent,
    UserIdentityComponent,
  ],
  providers: [
    {
      provide: MAT_BOTTOM_SHEET_DATA,
      useValue: {},
    },
  ],
})
export class DashboardModule {
}
