import { NgIf, NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';

import { animatedClock } from '../../../icons';

@Component({
  selector: 'app-measurement-clock',
  templateUrl: './measurement-clock.component.html',
  styleUrls: ['./measurement-clock.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    NgIconComponent,
  ],
  providers: [
    provideIcons({
      animatedClock,
    }),
  ],
})

export class MeasurementClockComponent {
  @Input() value = '00:00:00';
}
