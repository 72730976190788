import {
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroBriefcase, heroUser } from '@ng-icons/heroicons/outline';
import { heroStarSolid } from '@ng-icons/heroicons/solid';
import { TranslateModule } from '@ngx-translate/core';
import { Activity, Tag, Visibility } from 'ngx-atred-api-connectors';

import { DEFAULT_TYPOLOGY, getTypeById } from '../../../core/utils';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    NgIconComponent,
    NgIf,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroStarSolid,
      heroBriefcase,
      heroUser,
    }),
  ],
})
export class ActivityComponent {
  @Input() activity?: Activity & { tags?: Tag[] };
  @Output() readonly save = new EventEmitter<Activity>();

  readonly getTypeById = getTypeById;
  readonly Visibility = Visibility;
  readonly DEFAULT_TYPOLOGY = DEFAULT_TYPOLOGY;
}
