import { NgClass, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-upsert-legacy-action-notes-dialog',
  templateUrl: './upsert-legacy-action-notes-dialog.component.html',
  styleUrls: ['./upsert-legacy-action-notes-dialog.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
/**
 * @deprecated use `UpsertActionNotesDialogComponent` instead.
 */
export class UpsertLegacyActionNotesDialogComponent {
  private readonly formBuilder = inject(FormBuilder);
  readonly matDialogRef = inject(MatDialogRef);
  readonly data: { prevNote?: string, readOnly?: boolean } = inject(MAT_DIALOG_DATA);

  readonly upsertLegacyNoteForm = this.formBuilder.group({
    note: this.formBuilder.control(this.data.prevNote, [Validators.required]),
  });

  async upsertNote() {
    const { note } = this.upsertLegacyNoteForm.getRawValue();

    return this.matDialogRef.close(note);
  }
}
