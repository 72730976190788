<div
  class="flex h-16 w-screen flex-row items-center gap-3 bg-core-accent px-4 py-2 font-light tracking-wide text-white"
  (click)="openActivityMeasureBottomSheet()">
  <button
    *ngIf="(measure$ | async)?.state$ | async as state"
    class="flex cursor-pointer items-center rounded-full bg-white p-1 transition-all delay-75 hover:bg-gray-100"
    type="button">
    <ng-icon
      *ngIf="state === 'pause' || state === 'stop'"
      name="heroPlaySolid"
      class="text-2xl text-core-accent">
    </ng-icon>
    <ng-icon
      *ngIf="state === 'resume'"
      name="heroPlayPauseSolid"
      class="text-core-accent">
    </ng-icon>
    <ng-icon
      *ngIf="state === 'play'"
      name="heroPauseSolid"
      class="text-2xl text-core-accent">
    </ng-icon>
  </button>
  <span class="line-clamp-2 w-full cursor-pointer text-base">{{ data.activity?.name }}</span>
</div>
