<div class="flex min-h-full w-full flex-row items-center justify-center gap-2 p-4 md:h-full">
  <div class="flex h-full w-full flex-col justify-center gap-8">
    <app-user-identity [user]="user$ | async">
      <span class="text-base capitalize-first">{{ today | date : 'EEEE, d MMMM y'}}</span>
    </app-user-identity>
    <app-sidenav [class.max-sm:hidden]="router.url !== '/dashboard'"></app-sidenav>

    <ng-container *ngIf="{ value: hasLiveMeasure$ | async } as hasMeasure">
      <button
        *ngIf="!hasMeasure.value"
        class="mx-auto flex w-1/2 justify-center gap-3 self-end rounded-full bg-core-accent px-6 py-2 text-sm text-white md:hidden"
        (click)="openQuickMeasureBottomSheet()">
        <ng-icon
          name="brandActivityTime"
          class="fill-white"></ng-icon>
        <span>
          {{ 'i18n.Common.measureActivity' | translate }}
        </span>
      </button>
    </ng-container>

  </div>

  <div
    class="hidden h-full w-full flex-col items-center justify-center rounded-2xl bg-brand-light md:flex"
    [ngClass]="{
      'max-sm:!block max-sm:!absolute max-sm:!inset-0 max-sm:!bg-brand': router.url !== '/dashboard'
    }">
    <div class="mx-auto flex h-full w-full flex-col items-center justify-center">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
