<div
  *ngIf="data.activity && {
    state: state$ | async,
    time: (((measure$ | async)?.time$ | async) ?? 0) / 1000 | formatTime
  } as resolved"
  class="flex h-full min-h-[80vh] flex-col gap-2 overflow-y-scroll bg-core-accent px-6 py-4 text-white">
  <div class="sticky top-0 z-50 flex flex-col gap-4 bg-core-accent">
    <span class="sticky text-2xl">{{ data.activity.name }}</span>

    <div class="flex flex-row items-center justify-stretch gap-2">
      <div class="flex flex-col justify-start rounded-lg border border-core-light p-2">
        <div class="flex flex-row items-center">
          <ng-icon
            class="mb-1 mr-2 fill-white"
            name="brandTime"></ng-icon>
          <span>
            {{'i18n.Common.initialTime' | translate }}
          </span>
        </div>
        <div class="flex flex-row items-center">
          <span class="text-base">
            {{ data.activity.initialTimeSpan ?? '00:00:00' | toHoursMinutes }}
          </span>
          <sup>h/set</sup>
        </div>
      </div>

      <div class="flex flex-col justify-start rounded-lg border border-core-light p-2">
        <div class="flex flex-row items-center">
          <ng-icon
            class="mb-1 mr-2 fill-white"
            name="brandActivityTime"></ng-icon>
          <span>
            {{'i18n.Common.measuredTime' | translate }}
          </span>
        </div>
        <div class="flex flex-row items-center">
          <span class="text-base">
            {{ retrieveMeasuredTimesSum([data.activity]) | toHoursMinutes }}
          </span>
          <sup>h/set</sup>
        </div>
      </div>

      <div class="flex flex-col justify-start rounded-lg border border-core-light p-2">
        <div class="flex flex-row items-center">
          <ng-icon
            class="mb-1 mr-2 fill-white"
            name="brandFlag"></ng-icon>
          <span>
            {{'i18n.Common.targetTime' | translate }}
          </span>
        </div>
        <div class="flex flex-row items-center">
          <span class="text-base">
            {{ data.activity.targetTimeSpan ?? '00:00:00' | toHoursMinutes }}
          </span>
          <sup>h/set</sup>
        </div>
      </div>
    </div>
  </div>

  <app-measurement-clock
    *ngIf="measure$ | async as measure"
    class="my-auto"
    [value]="resolved.time"></app-measurement-clock>

  <div class="mx-auto mt-auto flex flex-row items-center justify-center gap-2">
    <button
      *ngIf="['pause', 'stop', 'resume'].includes(resolved.state ?? '')"
      class="flex cursor-pointer items-center rounded-full bg-white p-5 transition-all delay-75 hover:bg-gray-100"
      (click)="commitMeasure(resolved.time)">
      <ng-icon
        class="text-2xl text-core-accent"
        name="heroPlus"></ng-icon>
    </button>

    <button
      class="flex cursor-pointer items-center rounded-full bg-white p-5 transition-all delay-75 hover:bg-gray-100"
      type="button"
      (click)="toggleMeasure()">
      <ng-icon
        *ngIf="resolved.state === 'pause'"
        name="heroPlaySolid"
        class="text-2xl text-core-accent">
      </ng-icon>
      <ng-icon
        *ngIf="resolved.state === 'resume'"
        name="heroPlayPauseSolid"
        class="text-2xl text-core-accent">
      </ng-icon>
      <ng-icon
        *ngIf="resolved.state === 'play'"
        name="heroPauseSolid"
        class="text-2xl text-core-accent">
      </ng-icon>
    </button>

    <button
      *ngIf="['pause', 'stop', 'resume'].includes(resolved.state ?? '')"
      class="flex cursor-pointer items-center rounded-full bg-white p-5 transition-all delay-75 hover:bg-gray-100"
      (click)="commitMeasure(resolved.time)">
      <ng-icon
        class="text-2xl text-core-accent"
        name="heroTrash"></ng-icon>
    </button>
  </div>
</div>
