import { Pipe, PipeTransform } from '@angular/core';

import { transformSecToTimeSpan } from '../utils';

@Pipe({
  name: 'formatTime',
  standalone: true,
})
export class FormatTimePipe implements PipeTransform {
  readonly transform = transformSecToTimeSpan;
}
