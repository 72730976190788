import { BehaviorSubject } from 'rxjs';

const now = () => performance.now();

export class Stopwatch {
  private startedAt?: number;
  private cumulatedTime = 0;
  private readonly running = new BehaviorSubject(false);

  readonly running$ = this.running.asObservable();

  get time() {
    return this.startedAt !== undefined
      ? now() - this.startedAt + this.cumulatedTime
      : this.cumulatedTime;
  }

  start() {
    if (!this.running.value) {
      this.startedAt = now();
      this.running.next(true);
    }
  }

  stop() {
    if (this.running.value) {
      this.cumulatedTime = this.time;
      this.startedAt = undefined;
      this.running.next(false);
    }
  }

  reset() {
    this.stop();
    this.cumulatedTime = 0;
  }
}
