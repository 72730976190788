import { DatePipe, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroEllipsisVertical,
  heroClock,
  heroUserGroup,
} from '@ng-icons/heroicons/outline';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentState, SheetItem } from 'ngx-atred-api-connectors';
import { firstValueFrom } from 'rxjs';

import { CheckpointsService } from '../../../../../core/services/checkpoints.service';
import { SheetContextMenuComponent } from '../../../../../shared';
import {
  AvatarSize,
  UserIdentityComponent,
} from '../../../../../shared/components/user-identity/user-identity.component';

@Component({
  selector: 'app-document-list-item',
  templateUrl: './document-list-item.component.html',
  styleUrls: ['./document-list-item.component.scss'],
  standalone: true,
  imports: [
    DatePipe,
    MatBottomSheetModule,
    MatMenuModule,
    MatTooltipModule,
    NgFor,
    NgIconComponent,
    NgIf,
    SheetContextMenuComponent,
    TranslateModule,
    UserIdentityComponent,
  ],
  providers: [
    provideIcons({
      heroEllipsisVertical,
      heroClock,
      heroUserGroup,
    }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentListItemComponent implements OnInit {
  private readonly bottomSheet = inject(MatBottomSheet);
  private readonly checkpointsService = inject(CheckpointsService);
  @Input() sheet?: SheetItem;

  @Output() readonly remove = new EventEmitter<SheetItem>();
  @Output() readonly edit = new EventEmitter<SheetItem>();
  @Output() readonly share = new EventEmitter<SheetItem>();
  @Output() readonly toggleState = new EventEmitter<SheetItem>();

  readonly Math = Math;
  readonly AvatarSize = AvatarSize;
  readonly DocumentState = DocumentState;
  existingCheckpoints = false;

  ngOnInit() {
    void this.checkExistingCheckpoint();
  }

  async checkExistingCheckpoint() {
    if (this.sheet) {
      this.existingCheckpoints = await this.checkpointsService.checkExistingCheckpoints(this.sheet.id);
    }
  }

  async openContextMenuBottomSheet() {
    if (!this.sheet) {
      return;
    }

    const res: string = await firstValueFrom(
      this.bottomSheet.open(SheetContextMenuComponent, {
        data: {
          id: this.sheet.id,
          state: this.sheet.state,
          disableShare: this.existingCheckpoints,
        },
      }).afterDismissed(),
    );

    switch (res) {
      case 'remove':
        this.remove.emit(this.sheet);
        break;
      case 'edit':
        this.edit.emit(this.sheet);
        break;
      case 'share':
        this.share.emit(this.sheet);
        break;
      case 'toggle-state':
        this.toggleState.emit(this.sheet);
        break;
      default:
    }
  }
}
