import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Operation, applyReducer, compare } from 'fast-json-patch';
import {
  Activity,
  Document,
  DocumentState,
  OperationType,
  JsonPatchOperation,
  Sheet,
  SheetsService as SheetApi,
  Tag,
} from 'ngx-atred-api-connectors';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { GenericDialogService } from '../../shared/generic-dialog';
import { required } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class SheetsService {
  private readonly translate = inject(TranslateService);
  private readonly dialogService = inject(GenericDialogService);
  private readonly sheetsApi = inject(SheetApi);

  readonly listChanged$ = new BehaviorSubject(undefined);

  async remove(id: string) {
    const res = await this.dialogService.confirm({
      title: this.translate.instant('Rimozione documento ATRED'),
      description: this.translate.instant(
        'Sei sicuro di volere eliminare questo documento? Tutti i dati associati ad esso verranno persi.',
      ),
      primaryLabel: this.translate.instant('Ok'),
      secondaryLabel: this.translate.instant('Cancella'),
    });

    if (res) {
      await firstValueFrom(this.sheetsApi.v0SheetsIdDelete({ id }));
      this.listChanged$.next(undefined);
    }
  }

  async updateInfo(id: string, payload: { name: string, description?: string, hours?: string }) {
    const body: JsonPatchOperation[] = [
      {
        op: OperationType.Replace,
        path: '/name',
        value: payload.name,
      },
      {
        op: OperationType.Replace,
        path: '/description',
        value: payload.description,
      },
      {
        op: OperationType.Replace,
        path: '/workingTimeSpan',
        value: payload.hours,
      },
    ];

    await firstValueFrom(this.sheetsApi.v0SheetsIdPatch({ id, body }));
  }

  async updateActiveWeek(id: string, payload: { activeWeek: number }) {
    const body: JsonPatchOperation[] = [
      {
        op: OperationType.Replace,
        path: '/activeWeek',
        value: payload.activeWeek,
      },
    ];

    await firstValueFrom(this.sheetsApi.v0SheetsIdPatch({ id, body }));
    return firstValueFrom(this.sheetsApi.v0SheetsIdGet({ id }));
  }

  async toggleState(sheetId: string, state: DocumentState) {
    required(sheetId);

    const newState = state === DocumentState.Open
      ? DocumentState.Closed
      : DocumentState.Open;

    const body: JsonPatchOperation[] = [{
      op: OperationType.Replace,
      path: '/state',
      value: newState,
    }];

    await firstValueFrom(this.sheetsApi.v0SheetsIdPatch({ id: sheetId, body }));

    this.listChanged$.next(undefined);
  }

  async addActivity(sheet: Sheet, activity: Activity): Promise<Sheet> {
    required(sheet.id);

    const op: JsonPatchOperation = {
      op: OperationType.Add,
      path: '/activities/-',
      value: activity,
    };

    await firstValueFrom(this.sheetsApi.v0SheetsIdPatch({
      id: sheet.id,
      body: [op],
    }));

    const document = applyReducer(sheet.document, op as Operation, 0);
    return { ...sheet, document };
  }

  async updateActivity(sheet: Sheet, activity: Activity): Promise<Sheet | undefined> {
    required(sheet.id);
    required(sheet.document);

    const newDocument: Document = {
      ...sheet.document,
      activities: sheet.document.activities.map((a) => (activity.id === a.id ? activity : a)),
    };
    const body = compare(sheet.document, newDocument) as JsonPatchOperation[];

    if (!body.length) {
      return undefined;
    }

    await firstValueFrom(this.sheetsApi.v0SheetsIdPatch({ id: sheet.id, body }));

    return { ...sheet, document: newDocument };
  }

  async deleteActivity(index: number, sheet: Sheet) {
    required(sheet.id);
    required(sheet.document);

    const newDocument: Document = {
      ...sheet.document,
      activities: sheet.document.activities.filter((a, i) => i !== index),
    };
    const body = compare(sheet.document, newDocument) as JsonPatchOperation[];
    await firstValueFrom(this.sheetsApi.v0SheetsIdPatch({ id: sheet.id, body }));

    return { ...sheet, document: newDocument };
  }

  async addInterruptions(id: string, value: number) {
    const body: JsonPatchOperation[] = [
      {
        op: OperationType.Add,
        path: '/interruptions',
        value,
      },
    ];

    await firstValueFrom(this.sheetsApi.v0SheetsIdPatch({ id, body }));
  }

  async updateInterruptions(id: string, value: number) {
    const body: JsonPatchOperation[] = [
      {
        op: OperationType.Replace,
        path: '/interruptions',
        value,
      },
    ];

    await firstValueFrom(this.sheetsApi.v0SheetsIdPatch({ id, body }));
  }

  async addTag(tag: { name: string; color?: string | null }, sheet: Sheet) {
    const newTag: Tag = { ...tag, id: uuidv4() };
    required(sheet.id);
    required(sheet.document);

    const op: JsonPatchOperation = {
      op: OperationType.Add,
      path: '/tags/-',
      value: newTag,
    };

    await firstValueFrom(this.sheetsApi.v0SheetsIdPatch({
      id: sheet.id,
      body: [op],
    }));

    const document = applyReducer(sheet.document, op as Operation, 0);
    return { ...sheet, document };
  }

  async updateTag(sheet: Sheet, tag: Tag): Promise<Sheet> {
    required(sheet.id);
    required(sheet.document);

    const newDocument: Document = {
      ...sheet.document,
      tags: sheet.document.tags.map((t) => (t.id === tag.id ? tag : t)),
    };
    const body = compare(sheet.document, newDocument) as JsonPatchOperation[];

    await firstValueFrom(this.sheetsApi.v0SheetsIdPatch({ id: sheet.id, body }));

    return { ...sheet, document: newDocument };
  }
}
