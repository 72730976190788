import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  first,
  from,
  of,
  switchMap,
} from 'rxjs';

import { AuthService } from '../services/auth.service';

const REFRESH_TOKEN_API_PATH = 'refresh';
const LOGIN_API_PATH = 'login';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  private readonly authService = inject(AuthService);

  private refreshTokenInProgress = false;
  // It contains the `accessToken`
  private readonly refreshTokenSubject = new BehaviorSubject<string | undefined>(undefined);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const tokenRequest = this.addAuthenticationToken(request);

    return next.handle(tokenRequest).pipe(
      catchError((error) => {
        if (tokenRequest.url.includes(REFRESH_TOKEN_API_PATH) || tokenRequest.url.includes(LOGIN_API_PATH)) {
          if (tokenRequest.url.includes(REFRESH_TOKEN_API_PATH)) {
            void this.authService.logout();
          }

          return of(error);
        }

        if (error.status !== 401) {
          return of(error);
        }

        if (this.refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject.pipe(
            first((result) => !!result),
            switchMap((accessToken) => next.handle(this.addAuthenticationToken(tokenRequest, accessToken))),
          );
        }
        this.refreshTokenInProgress = true;

        // Set the refreshTokenSubject to null
        // so that subsequent API calls will wait until the new token has been retrieved
        this.refreshTokenSubject.next(undefined);

        // // Call auth.refreshAccessToken (this is an Observable that will be returned)
        return from(this.authService.refresh(
          this.authService.refreshToken,
          this.authService.accessToken,
        )).pipe(
          switchMap((data) => {
            // When the call to refreshToken completes we reset the refreshTokenInProgress to false
            // for the next time the token needs to be refreshed
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(data?.authToken);

            return next.handle(this.addAuthenticationToken(tokenRequest, data?.authToken));
          }),
          catchError((err) => {
            this.refreshTokenInProgress = false;
            if (err.url.includes(REFRESH_TOKEN_API_PATH)) {
              // FIXME: Navigate to the login
            }
            return of(error);
          }),
        );
      }),
    );
  }

  private addAuthenticationToken(request: HttpRequest<unknown>, token?: string) {
    const accessToken = token || this.authService.accessToken;

    if (!accessToken) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}
