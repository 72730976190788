import { AsyncPipe, NgFor } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  DocumentState,
  GroupsService,
  SharesService,
  SheetsService,
} from 'ngx-atred-api-connectors';
import { Observable, map, of } from 'rxjs';

import { SidenavLinkComponent } from './sidenav-link/sidenav-link.component';
import { AuthService, getUserId } from '../../../core/services';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    NgFor,
    SidenavLinkComponent,
  ],
})
export class SidenavComponent {
  private readonly userId = getUserId(inject(AuthService).accessToken);
  readonly documentCount$: Observable<number | undefined> = inject(SheetsService).v0SheetsGet().pipe(
    map((sheets) => sheets.filter((sheet) => sheet.state === DocumentState.Open).length),
  );

  readonly sharedCount$: Observable<number | undefined> = inject(SharesService).v0SharesGet({
    shareeId: this.userId,
  }).pipe(
    map((sheets) => sheets.length),
  );

  readonly groupCount$: Observable<number | undefined> = inject(GroupsService).v0GroupsGet().pipe(
    map((groups) => groups.length),
  );

  readonly adviceCount$: Observable<number | undefined> = of(0);
}
