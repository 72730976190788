import { Pipe, PipeTransform } from '@angular/core';

import { transformTimespanToHoursMinutes } from '../utils';

@Pipe({
  name: 'toHoursMinutes',
  standalone: true,
})
export class ToHoursMinutesPipe implements PipeTransform {
  readonly transform = transformTimespanToHoursMinutes;
}
