import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroCheck } from '@ng-icons/heroicons/outline';
import { TranslateModule } from '@ngx-translate/core';
import { Tag } from 'ngx-atred-api-connectors';

import { TagMenuComponent } from '../tag-menu/tag-menu.component';

@Component({
  selector: 'app-tag-menu-bottom-sheet',
  templateUrl: './tag-menu-bottom-sheet.component.html',
  styleUrls: ['./tag-menu-bottom-sheet.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgIconComponent,
    NgIf,
    TagMenuComponent,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroCheck,
    }),
  ],
})

export class TagMenuBottomSheetComponent {
  readonly bottomSheetRef = inject(MatBottomSheetRef<TagMenuBottomSheetComponent>);
  readonly data: Tag[] = inject(MAT_BOTTOM_SHEET_DATA);
}
