import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroLockClosed, heroMagnifyingGlass, heroXMark } from '@ng-icons/heroicons/outline';
import { TranslateModule } from '@ngx-translate/core';
import { Activity, SheetItem, SheetsService as SheetsApi } from 'ngx-atred-api-connectors';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';

import { SheetsService } from '../../../core/services';
import { MeasureOverlayService } from '../../../core/services/measure-overlay.service';
import {
  isNotNullOrUndefined,
  nullThenValueDelayedBy,
  switchMapTail,
} from '../../../core/utils';
import { SearchBarComponent } from '../../../shared';
import { ActivityComponent } from '../../../shared/components/activity/activity.component';
import { DocumentListComponent } from '../my-documents-page/documents-list/document-list.component';

@Component({
  selector: 'app-quick-measure-bottom-sheet',
  templateUrl: './quick-measure-bottom-sheet.component.html',
  styleUrls: ['./quick-measure-bottom-sheet.component.scss'],
  standalone: true,
  imports: [
    ActivityComponent,
    AsyncPipe,
    DocumentListComponent,
    FormsModule,
    NgClass,
    NgFor,
    NgFor,
    NgIconComponent,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    SearchBarComponent,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroLockClosed,
      heroMagnifyingGlass,
      heroXMark,
    }),
  ],
})

export class QuickMeasureBottomSheetComponent {
  readonly bottomSheetRef = inject(MatBottomSheetRef<QuickMeasureBottomSheetComponent>);

  private readonly measureOverlayService = inject(MeasureOverlayService);
  private readonly sheetsApi = inject(SheetsApi);
  private readonly sheetsService = inject(SheetsService);

  readonly selectedSheet$ = new BehaviorSubject<SheetItem | undefined>(undefined);
  get selectedSheet(): SheetItem | undefined {
    return this.selectedSheet$.value;
  }
  set selectedSheet(value: SheetItem | undefined) {
    this.selectedSheet$.next(value);
  }

  readonly selectedActivity$ = new BehaviorSubject<Activity | undefined>(undefined);
  get selectedActivity(): Activity | undefined {
    return this.selectedActivity$.value;
  }
  set selectedActivity(value: Activity | undefined) {
    this.selectedActivity$.next(value);
  }

  private steps = ['select-sheet', 'select-activity', 'measuring'];
  private currentStepIndex = 0;

  getCurrentStep() {
    return this.steps[this.currentStepIndex];
  }
  canProceed() {
    switch (this.currentStepIndex) {
      case 0:
        return !!this.selectedSheet;
      case 1:
        return !!this.selectedSheet && !!this.selectedActivity;
      case 2:
        return true;
      default:
        return false;
    }
  }
  goToNextStep(): void {
    if (this.currentStepIndex < this.steps.length - 1) {
      this.currentStepIndex += 1;
    }

    if (this.currentStepIndex === 2) {
      this.measureOverlayService.data$.next({ sheet: this.selectedSheet, activity: this.selectedActivity });
      this.bottomSheetRef.dismiss({ sheet: this.selectedSheet, activity: this.selectedActivity });
    }
  }
  goToPreviousStep(): void {
    if (this.currentStepIndex === 1) {
      this.selectedActivity$.next(undefined);
    }

    if (this.currentStepIndex > 0) {
      this.currentStepIndex -= 1;
    } else {
      this.bottomSheetRef.dismiss();
    }
  }

  readonly query$ = new BehaviorSubject('');
  get query() {
    return this.query$.value;
  }
  set query(value: string) {
    this.query$.next(value);
  }

  readonly sheets$ = combineLatest([
    this.query$.pipe(
      switchMapTail(nullThenValueDelayedBy(500)),
      distinctUntilChanged(),
    ),
    this.sheetsService.listChanged$,
  ]).pipe(
    switchMap(([query]) => (query === null ? of(null) : this.sheetsApi.v0SheetsGet({ name: query }))),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  private readonly activities$ = this.selectedSheet$.pipe(
    isNotNullOrUndefined(),
    switchMap(({ id }) => this.sheetsApi.v0SheetsIdGet({ id })),
    map((sheet) => {
      const { tags } = sheet.document;
      return sheet.document.activities.map((activity) => ({
        ...activity,
        tags: tags.filter((tag) => activity.tagIds.includes(tag.id)),
      }));
    }),
  );

  readonly activitiesList$ = combineLatest([
    this.query$,
    this.activities$,
  ]).pipe(
    map(([query, list]) => list?.filter((item) => new RegExp(query ?? '.', 'i').test(item.name))),
  );
}
