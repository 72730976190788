<div class="flex w-full flex-col items-start justify-center gap-6 rounded-lg border border-gray-200 bg-white/90 p-2 hover:opacity-90">
  <ng-container *ngIf="sheet; else skeletonDocument">
    <div class="flex w-full flex-col">
      <div class="flex w-full flex-row justify-between text-xl text-gray-900">
        <span
          *ngIf="sheet.name"
          class="max-w-xl truncate"
          [matTooltip]="sheet.name">
          {{ sheet.name }}
        </span>

        <button
          *ngIf="remove.observers.length"
          class="hidden cursor-pointer flex-row items-center rounded-full p-1 text-2xl transition-all delay-75 hover:bg-gray-200 md:flex"
          [matMenuTriggerFor]="documentMenu"
          (click)="$event.stopPropagation()">
          <ng-icon name="heroEllipsisVertical"></ng-icon>
        </button>

        <button
          *ngIf="remove.observers.length"
          class="flex cursor-pointer flex-row items-center rounded-full p-1 text-2xl transition-all delay-75 hover:bg-gray-200 md:hidden"
          (click)="$event.stopPropagation(); openContextMenuBottomSheet()">
          <ng-icon name="heroEllipsisVertical"></ng-icon>
        </button>

        <mat-menu
          #documentMenu="matMenu"
          class="context-menu">
          <app-sheet-context-menu
            [id]="sheet.id"
            [state]="sheet.state"
            [disableShare]="!existingCheckpoints"
            (edit)="edit.emit(sheet)"
            (toggleState)="toggleState.emit(sheet)"
            (remove)="remove.emit(sheet)"
            (share)="share.emit(sheet)">
          </app-sheet-context-menu>
        </mat-menu>
      </div>
      <span class="text-xs text-gray-400">
        {{ sheet.updatedAt | date : 'd MMMM y, HH:mm' }}
      </span>
    </div>

    <div
      *ngIf="share.observers.length"
      class="flex flex-row items-center gap-1">
      <div class="flex flex-row items-center rounded-3xl bg-blue-50 px-2 py-0.5">
        <ng-icon
          class="mr-1"
          name="heroClock"></ng-icon>
        <span>{{ sheet.checkpointCount }}</span>
      </div>

      <div
        *ngIf="true"
        class="flex flex-row items-center rounded-3xl bg-blue-50 px-2 py-0.5">
        <ng-icon
          class="mr-1"
          name="heroUserGroup"></ng-icon>
        <span>{{ sheet.groupCount }}</span>
      </div>
      <!-- <div class="flex flex-row items-center bg-blue-50 rounded-3xl p-1">
        TODO: Why are we showing the identity here?
        <app-user-identity
          [size]="AvatarSize.ExtraSmall"
          [user]="document.supervisor">
        </app-user-identity>
      </div> -->
    </div>
  </ng-container>

  <ng-template #skeletonDocument>
    <div class="flex flex-col items-start gap-2">
      <div class="h-8 w-52 animate-pulse rounded-lg bg-black/10"></div>
      <div class="h-4 w-40 animate-pulse rounded-md bg-black/10"></div>
    </div>

    <div class="mt-2 flex flex-row items-center gap-1">
      <div
        *ngFor="let badges of [].constructor(Math.floor(Math.random() * 3 + 1))"
        class="flex h-6 w-10 animate-pulse flex-row items-center rounded-3xl bg-blue-50 px-2 py-0.5"></div>

      <div class="flex animate-pulse flex-row items-center rounded-3xl bg-blue-50 p-1">
        <app-user-identity [size]="AvatarSize.ExtraSmall"></app-user-identity>
      </div>
    </div>
  </ng-template>

</div>
