import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AuthRoutingModule } from './auth-routing.module';

@NgModule({
  declarations: [],
  imports: [
    AuthRoutingModule,
    TranslateModule,
  ],
})
export class AuthModule {
}
