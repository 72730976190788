<form
  class="relative flex h-full w-full cursor-pointer flex-col items-start border-b border-gray-300 bg-white p-2 font-light tracking-wide text-gray-400 hover:opacity-80 md:flex-row md:px-3"
  [class.!bg-blue-50]="selected"
  [formGroup]="newTagForm"
  (ngSubmit)="onSubmit()">
  <div
    class="z-10 flex w-full shrink-0 grow flex-col rounded-lg px-3 py-1 md:w-fit md:px-0"
    [class.cursor-pointer]="!isEditing"
    (click)="tag && !isEditing && toggleApply.emit(tag)">
    <div class="flex flex-row items-center justify-between gap-2">
      <div
        class="h-6 w-6 shrink-0 grow cursor-pointer rounded transition-all hover:opacity-80"
        [ngStyle]="{'background-color': newTagForm.value.color}"
        (click)="isSelectingColors = !isSelectingColors; isEditing = true">
      </div>

      <input
        class="w-full truncate border-0 bg-transparent p-0 text-base text-black"
        formControlName="name"
        [class.cursor-pointer]="!isEditing"
        [readOnly]="!isEditing"
        [placeholder]="'i18n.TagMenu.tagName' | translate"
        (click)="isEditing ? $event.stopPropagation() : undefined">

      <button
        *ngIf="!isEditing"
        type="button"
        class="flex cursor-pointer flex-row items-center rounded p-1">
        <ng-icon
          class="text-base max-sm:text-xl"
          name="heroPencil"
          (click)="$event.stopPropagation(); isEditing = true">
        </ng-icon>
      </button>

      <ng-container *ngIf="isEditing">
        <button
          type="button"
          class="flex cursor-pointer flex-row items-center rounded p-1 text-red-500"
          (click)="$event.stopPropagation(); undoEditTag()">
          <ng-icon
            class="text-base max-sm:text-xl"
            name="heroXMark">
          </ng-icon>
        </button>
        <button
          class="flex cursor-pointer flex-row items-center rounded p-1 text-green-500"
          type="submit"
          (click)="$event.stopPropagation()">
          <ng-icon
            class="text-base max-sm:text-xl"
            name="heroCheck">
          </ng-icon>
        </button>
      </ng-container>
    </div>

    <div
      class="flex w-full flex-col transition-all"
      [class.hidden]="!isSelectingColors">

      <span class="mt-2 text-sm font-bold text-gray-900">
        {{ 'i18n.TagMenu.selectColor' | translate }}
      </span>

      <div class="flex flex-row flex-wrap items-center">
        <div
          *ngFor="let color of colors"
          class="m-1 flex h-6 w-6 cursor-pointer items-center justify-center rounded transition-all hover:opacity-80"
          [ngStyle]="{'background-color': color}"
          (click)="newTagForm.controls.color.setValue(color); isSelectingColors = false">
          <ng-icon
            *ngIf="newTagForm.value.color === color"
            class="font-bold text-white max-sm:text-xl"
            name="heroCheck">
          </ng-icon>
        </div>
      </div>
    </div>
  </div>
</form>
