import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import packageJson from '../package.json';

if (environment.production) {
  const replaysOnErrorSampleRate = environment.production ? 1.0 : 0;
  const replaysSessionSampleRate = environment.production ? 0.2 : 0;
  const tracesSampleRate = environment.production ? 0.4 : 0;

  Sentry.init({
    dsn: 'https://194f43dc879a439985edd9a30697b54d@o4505346582642688.ingest.sentry.io/4505347383164928',
    release: `atred-app@${packageJson.version}`,
    environment: environment.production ? 'production' : 'development',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', window.location.origin, environment.apiBasePath],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate,
    // Session Replay
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((err) => console.error(err));
