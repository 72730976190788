import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CheckpointItem, SheetsService } from 'ngx-atred-api-connectors';
import { firstValueFrom } from 'rxjs';

import { GenericDialogService } from '../../generic-dialog';

@Component({
  selector: 'app-list-document-checkpoint-dialog',
  templateUrl: './list-document-checkpoint-dialog.component.html',
  styleUrls: ['./list-document-checkpoint-dialog.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    NgClass,
    NgIf,
    NgFor,
    RouterLink,
    TranslateModule,
  ],
})
export class ListDocumentCheckpointsDialogComponent {
  private readonly data: { sheetId: string } = inject(MAT_DIALOG_DATA);
  private readonly dialogService = inject(GenericDialogService);
  private readonly sheetsApi = inject(SheetsService);
  private readonly translate = inject(TranslateService);
  readonly matDialogRef = inject(MatDialogRef);

  readonly checkpoints$ = this.sheetsApi.v0SheetsSheetIdCheckpointsGet({ sheetId: this.sheetId });

  selectedCheckpoint?: CheckpointItem;

  get sheetId() {
    return this.data.sheetId;
  }

  // TODO: What happen if you remove a checkpoint
  async removeCheckpoint(id: string) {
    const res = await this.dialogService.confirm({
      title: this.translate.instant('Rimozione checkpoint'),
      description: this.translate.instant(
        'Sei sicuro di volere eliminare questo checkpoint? Tutti i dati associati ad esso verranno persi.',
      ),
      primaryLabel: this.translate.instant('Ok'),
      secondaryLabel: this.translate.instant('Cancella'),
    });

    if (res) {
      await firstValueFrom(this.sheetsApi.v0SheetsSheetIdCheckpointsIdDelete({ sheetId: this.sheetId, id }));
    }
  }
}
